import { useContext, useEffect, useState } from "react";
import { NavbarDashboard } from "../../components/navbar";
import { UserContext } from "src/context/UserContext";
import {
  CardScreeningEmpty,
  CardScreeningResult,
} from "./component/card-screening-result";
import { UntukmuSection } from "./section/untukmu-section";
import { Footer } from "src/components/footer";
import { EdukasiSection } from "./section/edukasi-section";
import { ButtonPrimary, ButtonPrimaryExpand } from "src/components/button";
import { ScreeningResult } from "./section/screening-result";
import { dataScreeningResultTahapRemaja } from "src/data/screening-result/tahap-remaja";
import { dataScreeningResultStatusGizi } from "src/data/screening-result/status-gizi";
import { dataScreeningResultPotensiAnemia } from "src/data/screening-result/potensi-anemia";
import { useNavigate } from "react-router-dom";
import { GameSection } from "./section/game-section";

export function UserDashboardPage({ subMenuPath }) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const subMenu = [
    { id: 1, title: "Untukmu" },
    { id: 2, title: "Edukasi" },
    { id: 3, title: "Games" },
  ];

  const [subMenuActive, setSubMenuActive] = useState(1);
  const [screeningResult, setScreeningResult] = useState(null);

  useEffect(() => {
    if (user.screening.status_screening == 1) navigate("/screening");
    setSubMenuActive(subMenuPath ?? 1);
  }, [subMenuPath]);

  return (
    <div className="">
      {screeningResult == "tahap-remaja" ? (
        <div className="fixed z-50 flex justify-center items-center w-full h-full">
          <ScreeningResult
            handleClose={() => {
              setScreeningResult(null);
            }}
            data={
              user.screening.age == 1
                ? dataScreeningResultTahapRemaja[
                    user.gender == "Laki-laki" ? 1 : 0
                  ].value[0]
                : user.screening.age == 2
                ? dataScreeningResultTahapRemaja[
                    user.gender == "Laki-laki" ? 1 : 0
                  ].value[1]
                : user.screening.age == 3
                ? dataScreeningResultTahapRemaja[
                    user.gender == "Laki-laki" ? 1 : 0
                  ].value[2]
                : null
            }
          />
        </div>
      ) : screeningResult == "status-gizi" ? (
        <div className="fixed z-50 flex justify-center items-center w-full h-full">
          <ScreeningResult
            handleClose={() => {
              setScreeningResult(null);
            }}
            data={
              user.screening.imt < 18.5
                ? dataScreeningResultStatusGizi[0]
                : user.screening.imt >= 18.5 && user.screening.imt < 25
                ? dataScreeningResultStatusGizi[1]
                : user.screening.imt >= 25 && user.screening.imt < 30
                ? dataScreeningResultStatusGizi[2]
                : user.screening.imt > -30
                ? dataScreeningResultStatusGizi[3]
                : null
            }
          />
        </div>
      ) : screeningResult == "potensi-anemia" ? (
        <div className="fixed z-50 flex justify-center items-center w-full h-full">
          <ScreeningResult
            handleClose={() => {
              setScreeningResult(null);
            }}
            data={
              user.screening.blood_pressure >= 5
                ? dataScreeningResultPotensiAnemia[0]
                : dataScreeningResultPotensiAnemia[1]
            }
          />
        </div>
      ) : null}

      <NavbarDashboard />
      <div className="container lg:w-10/12 w-11/12 lg:pt-28 pt-24 mx-auto mb-8 min-h-screen">
        <div className="grid grid-cols-12 gap-6 w-full">
          <div className="lg:col-span-3 col-span-12">
            <div className="w-full">
              <h3 className="f-h3">
                Halo, {user.gender == "Laki-laki" ? "Mas" : "Mbak"} {user.name}
              </h3>
            </div>
            {user.screening.status_screening <= 2 ? (
              <div className="mt-4 grid grid-cols-1 gap-3">
                <CardScreeningEmpty
                  title="Kategori Usia"
                  description="Belum melakukan skrining"
                  type="age"
                />
                <CardScreeningEmpty
                  title="Indeks Massa Tubuh"
                  description="Belum melakukan skrining"
                  type="imt"
                />
                <CardScreeningEmpty
                  title="Potensi Anemia"
                  description="Belum melakukan skrining"
                  type="blood-pressure"
                />
                <ButtonPrimaryExpand
                  text="Mulai Skrining"
                  callback={() => {
                    navigate("/screening");
                  }}
                />
              </div>
            ) : (
              <div className="mt-4 grid grid-cols-1 gap-3">
                <CardScreeningResult
                  title="Kategori Usia"
                  description={
                    user.screening.age == 1
                      ? "Remaja Awal (10-13 tahun)"
                      : user.screening.age == 2
                      ? "Remaja Tengah (14-17 tahun)"
                      : user.screening.age == 3
                      ? "Remaja Akhir (18-24 tahun)"
                      : "-"
                  }
                  type="age"
                  callback={(type) => {
                    setScreeningResult("tahap-remaja");
                  }}
                />
                <CardScreeningResult
                  title="Indeks Massa Tubuh"
                  description={
                    user.screening.imt < 18.5
                      ? "Underweight (<18)"
                      : user.screening.imt >= 18.5 && user.screening.imt < 25
                      ? "Normal (18.5-24.9)"
                      : user.screening.imt >= 25.0 && user.screening.imt < 30
                      ? "Overweight (25-29.9)"
                      : user.screening.imt >= 30
                      ? "Obesitas (>=30)"
                      : "-"
                  }
                  type="imt"
                  callback={(type) => {
                    setScreeningResult("status-gizi");
                  }}
                />
                <CardScreeningResult
                  title="Potensi Anemia"
                  description={
                    user.screening.blood_pressure >= 5
                      ? "Berpotensi Anemia"
                      : "Normal"
                  }
                  type="blood-pressure"
                  callback={(type) => {
                    setScreeningResult("potensi-anemia");
                  }}
                />
              </div>
            )}
          </div>
          <div className="lg:col-span-9 col-span-12 bg-neutral-100">
            <div className="w-full grid grid-cols-3">
              {subMenu.map((item) => {
                return (
                  <button
                    key={item.id}
                    onClick={() => {
                      setSubMenuActive(item.id);
                    }}
                    className={`text-neutral-1000 px-5 py-2 border-b-2  ${
                      item.id == subMenuActive
                        ? "border-neutral-1000 f-p2-m"
                        : "border-neutral-300 f-p2-r"
                    }`}
                  >
                    {item.title}
                  </button>
                );
              })}
            </div>
            {user.screening == null && subMenuActive == 1 ? (
              <div className="mt-5 flex flex-col items-center text-center lg:w-2/3 w-full mx-auto">
                <h4 className="f-p1-sb">Belum melakukan Skrining</h4>
                <p className="f-p2-sm">
                  Kamu perlu melakukan skrining terlebih dahulu untuk
                  mendapatkan konten rekomendasi. Atau lakukan nanti dan
                  jelajahi artikel dan video pilihan di Edukasi.
                </p>
                <div className="mt-3">
                  <ButtonPrimary text="Mulai Skrining" callback={() => {}} />
                </div>
              </div>
            ) : (
              <div>
                {subMenuActive == 1 ? (
                  <UntukmuSection />
                ) : subMenuActive == 2 ? (
                  <EdukasiSection />
                ) : (
                  <GameSection />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
