import {
  ButtonNeutral,
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { NavQuestion } from "../component/nav";
import { useEffect, useState } from "react";

export function QuestionChoice({
  value,
  show,
  handlePrev,
  handleNext,
  index,
  callback,
  selected,
}) {
  return (
    <div className={`flex-col w-full ${show ? "flex" : "hidden"}`}>
      <div className="mb-10">
        <NavQuestion
          handlePrev={() => handlePrev()}
          value={((index + 1) / 13) * 100 + "%"}
        />
      </div>
      <h3 className="f-h3 mb-4">{value.group}</h3>
      <p className="f-p1-r mb-4">{value.question}</p>
      <div className="flex flex-col text-center w-full mb-5">
        {value.choices.map((item, index) => {
          return (
            <div key={index} className="mb-2 w-full">
              {selected - 1 == index ? (
                <ButtonPrimaryExpand
                  text={item.label}
                  callback={() => {
                    callback(index + 1);
                  }}
                />
              ) : (
                <ButtonNeutral
                  text={item.label}
                  callback={() => {
                    callback(index + 1);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <div>
        {selected == null ? (
          <ButtonSecondaryExpand text="Lanjut" callback={() => {}} />
        ) : (
          <ButtonPrimaryExpand
            text="Lanjut"
            callback={() => {
              handleNext();
            }}
          />
        )}
      </div>
    </div>
  );
}

export function QuestionNumber({
  value,
  show,
  handlePrev,
  handleNext,
  index,
  weight,
  height,
  callback,
}) {
  const [gizi, setGizi] = useState({ weight: 0, height: 0 });

  useEffect(() => {
    setGizi({ weight: weight, height: height });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGizi({ ...gizi, [name]: value });
  };

  return (
    <div className={`flex-col w-full ${show ? "flex" : "hidden"}`}>
      <div className="mb-10">
        <NavQuestion
          handlePrev={() => handlePrev()}
          value={((index + 1) / 13) * 100 + "%"}
        />
      </div>
      <h3 className="f-h3 mb-4">{value.group}</h3>
      <p className="f-p1-r mb-2">{value.question_1}</p>
      <div className="flex w-full mb-5 items-center gap-2">
        <input
          name="weight"
          type="number"
          value={gizi.weight}
          onChange={handleChange}
          placeholder="Masukkan berat badan"
          className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 focus:outline-none focus:border-primary-main"
        />
        <p className="f-p1-m">KG</p>
      </div>
      <p className="f-p1-r mb-2">{value.question_2}</p>
      <div className="flex w-full mb-5 items-center gap-2">
        <input
          type="number"
          name="height"
          value={gizi.height}
          onChange={handleChange}
          placeholder="Masukkan tinggi badan"
          className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 focus:outline-none focus:border-primary-main"
        />
        <p className="f-p1-m">CM</p>
      </div>
      <div>
        {gizi.weight == 0 ||
        gizi.height == 0 ||
        gizi.weight == undefined ||
        gizi.height == undefined ? (
          <ButtonSecondaryExpand text="Lanjut" callback={() => {}} />
        ) : (
          <ButtonPrimaryExpand
            text="Lanjut"
            callback={() => {
              callback(gizi);
              handleNext();
            }}
          />
        )}
      </div>
    </div>
  );
}

export function QuestionChoiceImage({
  value,
  show,
  handlePrev,
  handleNext,
  index,
  callback,
  selected,
}) {
  return (
    <div className={`flex-col w-full ${show ? "flex" : "hidden"}`}>
      <div className="mb-10">
        <NavQuestion
          handlePrev={() => handlePrev()}
          value={((index + 1) / 13) * 100 + "%"}
        />
      </div>
      <h3 className="f-h3 mb-4">{value.group}</h3>
      {value.question.map((item, index) => {
        return item.type == "text" ? (
          <p key={index} className="f-p1-r mb-4">
            {item.value}
          </p>
        ) : item.type == "image" ? (
          <img key={index} src={item.value} className="w-48 mx-auto mb-6" />
        ) : item.type == "list" ? (
          <ul key={index} className="list-disc mb-4">
            {item.value.map((x, i) => {
              return <li className="f-p1-r mb-1">{x}</li>;
            })}
          </ul>
        ) : null;
      })}
      <div className="flex flex-col text-center w-full mb-5">
        {value.choices.map((item, index) => {
          return (
            <div key={index} className="mb-2 w-full">
              {selected - 1 == index ? (
                <ButtonPrimaryExpand
                  text={item.label}
                  callback={() => {
                    callback(index + 1);
                  }}
                />
              ) : (
                <ButtonNeutral
                  key={index}
                  text={item.label}
                  callback={() => {
                    callback(index + 1);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <div>
        {selected == null ? (
          <ButtonSecondaryExpand text="Lanjut" callback={() => {}} />
        ) : (
          <ButtonPrimaryExpand
            text="Lanjut"
            callback={() => {
              handleNext();
            }}
          />
        )}
      </div>
    </div>
  );
}
