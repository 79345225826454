import iconQuiz from "src/assets/Iconography/Outline/Game Logo Yes No.svg";
import iconPuzzle from "src/assets/Iconography/Outline/Game Logo Poster.svg";
import { useNavigate } from "react-router-dom";

export function GameSection() {
  const navigate = useNavigate();

  return (
    <div className="w-full">
      <p className="f-p1-r mt-5">
        Perdalam pengetahuan kesehatan reproduksi dengan cara yang menyenangkan
      </p>

      <div className="flex lg:flex-row flex-col gap-4 mt-5">
        <button
          onClick={() => {
            navigate("/game/quiz");
          }}
          className="relative lg:w-1/2 w-full h-[180px] bg-warning-main p-5 rounded-[8px] flex items-center justify-center"
        >
          <div className="absolute top-3 left-3">
            <img src={iconQuiz} />
          </div>
          <h1 className="f-h2 text-white text-center">Yes/No Quiz</h1>
        </button>
        <button
          onClick={() => {
            navigate("/game/puzzle");
          }}
          className="relative lg:w-1/2 w-full h-[180px] bg-success-main p-5 rounded-[8px] flex items-center justify-center"
        >
          <div className="absolute top-3 left-3">
            <img src={iconPuzzle} />
          </div>
          <h1 className="f-h2 text-white text-center">Puzzle Poster</h1>
        </button>
      </div>
    </div>
  );
}
