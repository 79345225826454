export const dataTebak = [
  {
    id: 1,
    question: "Masa remaja awal dimulai sejak umur 13 tahun",
    key: 0,
    reason: "Masa remaja awal dimulai sejak usia 10-13 tahun.",
  },
  {
    id: 2,
    question: "Usia 21 tergolong remaja jika belum menikah",
    key: 1,
    reason:
      "Menurut World Health Organization (WHO), remaja merupakan masyarakat yang berada di rentang usia 10 sampai 19 tahun. Adapun, menurut Peraturan Kesehatan RI Nomor 25 tahun 2014, remaja didefinisikan sebagai penduduk dalam rentang usia 10-18 tahun dan menurut Badan Kependudukan dan Keluarga Berencana (BKKBN) rentang usia remaja adalah 10-24 tahun dan belum menikah.",
  },
  {
    id: 3,
    question:
      "Seseorang berisiko terkena penyakit menular seksual (IMS) dari dudukan toilet",
    key: 0,
    reason:
      "Penyakit atau infeksi menular seksual tidak dapat hidup di luar tubuh untuk jangka waktu yang panjang. Terutama di permukaan dingin dan keras seperti dudukan toilet. Virus penyebab IMS tidak hidup di dalam urine. Jadi, kemungkinan tertular PMS dari dudukan toilet hanya mitos.",
  },
  {
    id: 4,
    question: "Berciuman dapat menularkan penyakit herpes",
    key: 1,
    reason:
      "Seseorang bisa tertular herpes simpleks tipe 1 saat sedang berciuman atau menggunakan peralatan pribadi bersama. Melansir laman sutterhealth.org, virus herpes simpleks dapat menempel di saliva, bahkan ketika orang tersebut tidak menunjukkan gejala apapun.",
  },
  {
    id: 5,
    question: "Berhubungan intim sekali tidak menyebabkan kehamilan",
    key: 0,
    reason:
      "Tidak perlu (berhubungan seks) berkali-kali untuk bisa hamil. Meskipun hanya baru sekali berhubungan seks tapi kalau terjadi pembuahan antara sel sperma dan telur, kehamilan tetap bisa terjadi. Apalagi jika dilakukan di masa subur, maka dapat meningkatkan risiko terjadinya kehamilan.",
  },
  {
    id: 6,
    question: "Anemia itu sama dengan darah rendah",
    key: 0,
    reason:
      "Darah rendah dan kurang darah adalah kondisi medis yang memiliki gejala yang hampir mirip tetapi sebenarnya berbeda. Tak heran jika sering kali keduanya dianggap sama. \n Padahal, jika dilihat dari pengertian, penyebab dan cara mengobatinya, darah rendah dan kurang darah merupakan jenis gangguan kesehatan yang berbeda. Darah rendah disebut hipotensi, sedangkan kurang darah disebut anemia.",
  },
  {
    id: 7,
    question:
      "Remaja putri berkembang menjadi “dewasa” lebih cepat daripada remaja putra",
    key: 1,
    reason:
      "Pada anak perempuan, mereka akan melewati masa pubertas dalam rentang usia 10–14 tahun. Sedangkan pada anak laki-laki, mereka akan melewati masa pubertas pada kisaran 12–16 tahun.",
  },
  {
    id: 8,
    question:
      "Benarkah “mager” atau malas gerak adalah salah satu penyebab obesitas?",
    key: 1,
    reason:
      "Malas bergerak bisa membuat lemak dalam tubuh terus menumpuk dan pada akhirnya akan membuat berat badan naik. Hal itu terjadi karena tidak adanya keseimbangan antara energi yang masuk dengan energi yang keluar dari tubuh. Selain itu, tidak berolahraga cenderung membuat seseorang memiliki pola makan yang buruk pula.",
  },
  {
    id: 9,
    question: "Apakah jerawat pada remaja itu nggak normal?",
    key: 0,
    reason:
      "Timbulnya jerawat pada remaja, terutama pada masa pubertas adalah hal yang wajar. Pada masa puber, aktivitas hormon di dalam tubuh meningkat. Hal ini kemudian menyebabkan kelenjar minyak menghasilkan sebum dalam jumlah lebih banyak dari yang dibutuhkan kulit, dan memicu munculnya jerawat.",
  },
  {
    id: 10,
    question: "Apakah remaja putri juga mengalami mimpi basah?",
    key: 1,
    reason:
      "emisi nokturnal atau biasa disebut mimpi basah memang lebih sering dikaitkan dengan proses pubertas pada laki-laki. Namun, ternyata remaja perempuan juga bisa mengalaminya, loh! Emisi nokturnal pada perempuan ditandai dengan keluarnya cairan vagina seperti saat menerima rangsangan seksual. Akan tetapi, tanda ini akan lebih sulit dikenali jika dibandingkan dengan emisi nokturnal pada laki-laki.",
  },
  {
    id: 11,
    question: "Apakah berat badan kurang dapat menyebabkan haid tidak teratur?",
    key: 1,
    reason:
      "Berat badan memiliki peran penting dalam siklus menstruasi. Karena berat badan akan mempengaruhi fluktuasi hormon di dalam tubuh. Saat berat normal lalu menjadi kurang, maka siklus menstruasi bisa terganggu.",
  },
  {
    id: 12,
    question: "Benarkah anemia hanya bisa dialami oleh remaja putri?",
    key: 0,
    reason:
      "anemia memang erat dengan remaja putri karena anemia dapat disebabkan oleh kekurangan darah akibat proses menstruasi. Namun, remaja laki-laki pun juga berisiko terkena anemia apabila zat besi dalam tubuh tidak mencukupi kebutuhan.",
  },
  {
    id: 13,
    question:
      "Apakah olahraga perlu dihindari agar berat badan bisa meningkat?",
    key: 0,
    reason:
      "Menambah porsi makan bukan satu-satunya cara untuk menambah berat badan, loh! Justru, porsi makan kita tetap harus seimbang dan perlu diimbangi dengan olahraga. Tujuannya, agar penambahan berat badan disebabkan oleh peningkatan massa otot, bukan lemak jahat yang tidak baik bagi tubuh.",
  },
  {
    id: 14,
    question:
      "Apakah kita bisa tertular HIV jika kita berpelukan dengan penderita HIV?",
    key: 0,
    reason:
      "Pada dasarnya, HIV dapat ditularkan melalui cairan tubuh termasuk darah, air mani, cairan vagina, dan ASI pada ibu yang terinfeksi HIV. Virus HIV tidak ditularkan melalui sentuhan kulit, misalnya dengan berjabat tangan atau berpelukan, percikan air liur saat penderitanya bersin, atau melalui keringat.",
  },
  {
    id: 15,
    question: "Apakah perempuan juga memiliki hormon testosteron?",
    key: 1,
    reason:
      "Kadar hormon testosteron yang terdapat pada tubuh perempuan memang tidak sebanyak laki-laki. Namun, hormon ini tetap memiliki fungsi yang penting bagi kesehatan wanita. Hormon testosteron berperan dalam mengatur libido atau gairah seksual dan menjaga vagina payudara, dan kesuburan.",
  },
];
