import img1_1 from "src/assets/Screening Result/status-gizi/<18.5/1.png";
import img2_1 from "src/assets/Screening Result/status-gizi/18.5-24.9/1.png";
import img2_2 from "src/assets/Screening Result/status-gizi/18.5-24.9/2.png";
import img2_3 from "src/assets/Screening Result/status-gizi/18.5-24.9/3.png";
import img3_1 from "src/assets/Screening Result/status-gizi/25-29.9/1.png";
import img3_2 from "src/assets/Screening Result/status-gizi/25-29.9/2.png";
import img4_1 from "src/assets/Screening Result/status-gizi/>=30/1.png";
import img4_2 from "src/assets/Screening Result/status-gizi/>=30/2.png";

export const dataScreeningResultStatusGizi = [
  {
    title: "Indeks Massa Tubuh (IMT) kamu adalah <18.5",
    label: "Berat badan kurang (Underweight)",
    text: "Yahh, berat badanmu masih kurang nih :(",
    image: img1_1,
    value: [
      {
        label: "Berat badan kurang (Underweight)",
        value: [
          {
            type: "text-center",
            value: "Yah, berat badanmu masih kurang, nih! :(",
          },
          {
            type: "image",
            value: img1_1,
          },
          {
            type: "text",
            value:
              "Berat badan kurang memang sering dialami remaja karena tingginya aktivitas seperti belajar, ekstrakurikuler, dan kegiatan lainnya membuat remaja kehilangan banyak energi. Ditambah lagi, pola makan yang nggak teratur mengakibatkan ketidakseimbangan antara energi yang masuk dengan energi yang dikeluarkan. ",
          },
          {
            type: "text",
            value:
              "Berat badan kurang atau underweight pada remaja adalah mudah terserang penyakit infeksi akibat sistem kekebalan tubuh yang menurun, kehilangan massa otot tubuh, rambut rontok, regulasi hormonal tidak teratur (gangguan kelenjar tiroid), dan dapat menyebabkan haid tidak teratur bahkan dapat tidak haid pada remaja putri, serta menimbulkan rasa kelelahan.",
          },
          {
            type: "text",
            value:
              "Tapi jangan khawatir, cara untuk meningkatkan berat badan itu mudah, kok! Prinsipnya terapkan keseimbangan energi yang positif. Dengan kata lain, energi yang masuk harus lebih besar dari energi yang keluar.",
          },
        ],
      },
      {
        label: "Tips yang bisa kamu coba",
        value: [
          {
            type: "list",
            title: "Berikut adalah tips yang bisa kamu coba!",
            value: [
              {
                type: "text",
                value: "Makan dengan porsi sedikit, tapi sering",
              },
              {
                type: "text",
                value:
                  "Tingkatkan asupan kalori \n Fun fact: menambah 500 kalori sehari dapat meningkatkan berat badan 0,5 - 1 kg/minggu",
              },
              {
                type: "text",
                value:
                  "Konsumsi air putih yang cukup, yaitu lebih dari 2 liter atau 8 gelas/hari",
              },
              {
                type: "text",
                value:
                  "Kreasikan makananmu agar kamu tertarik untuk memakannya",
              },
              {
                type: "text",
                value:
                  "Olahraga dan latih kekuatan ototmu \n Menambah berat badan bukan berarti makan apapun tanpa berpikir sehingga terlalu banyak lemak yang masuk ke tubuh. \n Maka dari itu, kamu juga perlu berolahraga. Olahraga juga bisa menambah BB dengan menambah massa otot. Hal tersebut lebih baik jika dibandingkan dengan pertambahan massa lemak tubuh. \n Lakukan push up, sit up, dan naik turun tangga minimal 30 menit/hari.",
              },
              {
                type: "text",
                value:
                  "Timbang berat badan secara berkala untuk mengetahui progresmu",
              },
            ],
          },
          {
            type: "text",
            value: "Semangat menambah berat badan!",
          },
        ],
      },
    ],
  },
  {
    title: "Indeks Massa Tubuh (IMT) kamu adalah 18.5-24.9",
    label: "Berat badan ideal (Normal)",
    text: "Selamat, ya! Berat badanmu ideal!",
    image: img2_1,
    value: [
      {
        label: "Berat badan ideal (Normal)",
        value: [
          {
            type: "text-center",
            value: "Selamat, ya! Berat badanmu ideal!",
          },
          {
            type: "image",
            value: img2_1,
          },
          {
            type: "text",
            value:
              "Tetap pertahankan berat badan idealmu dengan prinsip energi yang masuk sama dengan energi yang keluar. \n Kamu bisa menggunakan panduan isi piringku sebagai patokan angka kebutuhan gizi harianmu seperti gambar di bawah ini.",
          },
          {
            type: "image",
            value: img2_2,
          },
          {
            type: "text",
            value:
              "Selain itu, kamu juga bisa menerapkan pesan kesehatan di tumpeng gizi seimbang ini!",
          },
          {
            type: "image",
            value: img2_3,
          },
          {
            type: "text",
            value:
              "Oh iya, jangan lupa timbang berat badan secara berkala untuk memantau agar berat badanmu tetap berada pada batas normal.",
          },
          {
            type: "text",
            value: "Yuk, kita pertahankan berat badan yang ideal!",
          },
        ],
      },
    ],
  },
  {
    title: "Indeks Massa Tubuh (IMT) kamu adalah 25-29.9",
    label: "Berat badan berlebih (Overweight)",
    text: "Yahh, berat badanmu berlebih, nih! :(",
    image: img3_1,
    value: [
      {
        label: "Berat badan berlebih (Overweight)",
        value: [
          {
            type: "text-center",
            value: "Oh, no! Berat badanmu berlebih, nih! :(",
          },
          {
            type: "image",
            value: img3_1,
          },
          {
            type: "text",
            value:
              "Tahukah kamu, angka kejadian overweight di dunia semakin meningkat. Penyebabnya antara lain kebiasaan konsumsi makanan dan minuman tinggi kalori, rendahnya aktivitas fisik, efek samping obat - obatan, kurang tidur, pertambahan usia, dan masalah kesehatan tertentu (seperti sindrom Cushing dan hipertiroidisme). Hal ini juga ditunjang dengan merebaknya makanan dan minuman kekinian yang tinggi lemak dan gula.",
          },
          {
            type: "text",
            value:
              "Overweight berdampak negatif pada kesehatan tubuh secara menyeluruh. Sedangkan pada anak-anak dan remaja, overweight dapat mengarah ke obesitas yang dapat meningkatkan risiko penyakit jantung, prediabetes, gangguan tulang, nyeri sendi dan tulang, dan menurunkan rasa percaya diri.",
          },
          {
            type: "text",
            value:
              "Orang bertubuh besar sering diperlakukan tidak baik, atau dikenal fenomena fatphobia, sizeism, atau diskriminasi terhadap ukuran. Hal ini tidak boleh dibiarkan karena termasuk body shaming dan berdampak negatif pada kualitas hidup seseorang, termasuk menurunkan rasa percaya diri dan menyebabkan masalah mental (seperti depresi, kecemasan, dan gangguan stres pascatrauma).",
          },
          {
            type: "text",
            value:
              "Untuk menurunkan berat badan, kamu perlu mengingat prinsip “energi yang masuk lebih sedikit dari energi yang keluar”.",
          },
        ],
      },

      {
        label: "Tips yang bisa kamu coba",
        value: [
          {
            type: "list-image",
            title: "Selain itu, kamu juga bisa menerapkan tips berikut ini.",
            value: [
              {
                type: "vertical-list",
                value: [
                  {
                    type: "text",
                    value: "Menerapkan Pola Makan Sehat",
                  },
                  {
                    type: "text",
                    value:
                      "Menu makan harian sebaiknya mengandung nutrisi lengkap, terdiri dari karbohidrat, lemak, vitamin, dan mineral sesuai dengan panduan Isi Piringku.",
                  },
                  {
                    type: "image",
                    value: img3_2,
                  },
                  {
                    type: "text",
                    value:
                      "Jangan lupa untuk memenuhi kebutuhan cairan tubuh, baik dengan minum air putih, jus, atau konsumsi buah dan sayuran utuh.",
                  },
                ],
              },
              {
                type: "vertical-list",
                value: [
                  {
                    type: "text",
                    value: "Modifikasi Perilaku Makan",
                  },
                  {
                    type: "text",
                    value:
                      "Perilaku makan yang perlu diubah adalah kebiasaan makan tanpa “mikir” atau tanpa menyeleksi zat apa yang masuk ke tubuh kita dan seberapa banyak/sering zat tersebut masuk ke tubuh kita.",
                  },
                  {
                    type: "text",
                    value:
                      "Di era gempuran makanan dan minuman viral yang tinggi gula, kita harus mampu menahan keinginan makan kita di luar jam makan utama, serta mengontrol porsi dan jenis makanan yang dikonsumsi. Kalau pun kita ingin makan camilan, pastikan bahwa kita mengonsumsi camilan yang sehat, seperti buah-buahan segar di sela-sela waktu makan utama.",
                  },
                ],
              },
              {
                type: "vertical-list",
                value: [
                  {
                    type: "text",
                    value: "Aktivitas Fisik",
                  },
                  {
                    type: "text",
                    value:
                      "Salah satu faktor pencetus overweight adalah energi yang tidak dipakai dan menumpuk di dalam tubuh. Apalagi, saat dunia dalam genggaman kita, kita jadi melakukan semuanya lewat handphone sambil rebahan, kan?",
                  },
                  {
                    type: "text",
                    value:
                      "Maka dari itu, kita harus mengeluarkan energi tersebut dengan olahraga selama setidaknya 30 menit/hari, misalnya jalan kaki, sepak bola, bersepeda, berenang, dan basket.",
                  },
                ],
              },
              {
                type: "text",
                value:
                  "Timbang berat badan secara berkala untuk mengetahui progresmu",
              },
            ],
          },
          {
            type: "text",
            value:
              "Semangat menurunkan berat badan! Aku percaya kalau kamu pasti bisa!",
          },
        ],
      },
    ],
  },
  {
    title: "Indeks Massa Tubuh (IMT) kamu adalah >=30",
    label: "Berat badan berlebih",
    text: "Yahh, berat badanmu berlebih, nih! :(",
    image: img4_1,
    value: [
      {
        label: "Obesitas",
        value: [
          {
            type: "text-center",
            value: "Oh, no! Berat badanmu berlebih, nih! :(",
          },
          {
            type: "image",
            value: img4_1,
          },
          {
            type: "text",
            value:
              "Tahukah kamu, angka kejadian obesitas di dunia semakin meningkat. Penyebabnya antara lain kebiasaan konsumsi makanan dan minuman tinggi kalori, rendahnya aktivitas fisik, efek samping obat-obatan, kurang tidur, pertambahan usia, dan masalah kesehatan tertentu (seperti sindrom Cushing dan hipertiroidisme). Hal ini juga ditunjang dengan merebaknya makanan dan minuman kekinian yang tinggi lemak dan gula. ",
          },
          {
            type: "text",
            value:
              "Overweight berdampak negatif pada kesehatan tubuh secara menyeluruh. Sedangkan pada anak-anak dan remaja, obesitas dapat meningkatkan risiko penyakit jantung, prediabetes, gangguan tulang, nyeri sendi dan tulang, dan menurunkan rasa percaya diri.",
          },
          {
            type: "text",
            value:
              "Orang bertubuh besar sering diperlakukan tidak baik, atau dikenal fenomena fatphobia, sizeism, atau diskriminasi terhadap ukuran. Hal ini tidak boleh dibiarkan karena termasuk body shaming dan berdampak negatif pada kualitas hidup seseorang, termasuk menurunkan rasa percaya diri dan menyebabkan masalah mental (seperti depresi, kecemasan, dan gangguan stres pascatrauma).",
          },
          {
            type: "text",
            value:
              "Untuk menurunkan berat badan, kamu perlu mengingat prinsip “energi yang masuk lebih sedikit dari energi yang keluar”.",
          },
        ],
      },
      {
        label: "Tips yang bisa kamu coba",
        value: [
          {
            type: "list-image",
            title: "Selain itu, kamu juga bisa menerapkan tips berikut ini.",
            value: [
              {
                type: "vertical-list",
                value: [
                  {
                    type: "text",
                    value: "Menerapkan Pola Makan Sehat",
                  },
                  {
                    type: "text",
                    value:
                      "Menu makan harian sebaiknya mengandung nutrisi lengkap, terdiri dari karbohidrat, lemak, vitamin, dan mineral sesuai dengan panduan Isi Piringku.",
                  },
                  {
                    type: "image",
                    value: img4_2,
                  },
                  {
                    type: "text",
                    value:
                      "Jangan lupa untuk memenuhi kebutuhan cairan tubuh, baik dengan minum air putih, jus, atau konsumsi buah dan sayuran utuh.",
                  },
                ],
              },
              {
                type: "vertical-list",
                value: [
                  {
                    type: "text",
                    value: "Modifikasi Perilaku Makan",
                  },
                  {
                    type: "text",
                    value:
                      "Perilaku makan yang perlu diubah adalah kebiasaan makan tanpa “mikir” atau tanpa menyeleksi zat apa yang masuk ke tubuh kita dan seberapa banyak/sering zat tersebut masuk ke tubuh kita.",
                  },
                  {
                    type: "text",
                    value:
                      "Di era gempuran makanan dan minuman viral yang tinggi gula, kita harus mampu menahan keinginan makan kita di luar jam makan utama, serta mengontrol porsi dan jenis makanan yang dikonsumsi. Kalau pun kita ingin makan camilan, pastikan bahwa kita mengonsumsi camilan yang sehat, seperti buah-buahan segar di sela-sela waktu makan utama.",
                  },
                ],
              },
              {
                type: "vertical-list",
                value: [
                  {
                    type: "text",
                    value: "Aktivitas Fisik",
                  },
                  {
                    type: "text",
                    value:
                      "Salah satu faktor pencetus obesitas adalah energi yang tidak dipakai dan menumpuk di dalam tubuh. Apalagi, saat dunia dalam genggaman kita, kita jadi melakukan semuanya lewat handphone sambil rebahan, kan?",
                  },
                  {
                    type: "text",
                    value:
                      "Maka dari itu, kita harus mengeluarkan energi tersebut dengan olahraga selama setidaknya 30 menit/hari, misalnya jalan kaki, sepak bola, bersepeda, berenang, dan basket.",
                  },
                ],
              },
              {
                type: "text",
                value:
                  "Timbang berat badan secara berkala untuk mengetahui progresmu",
              },
            ],
          },
          {
            type: "text",
            value:
              "Semangat menurunkan berat badan! Aku percaya kalau kamu pasti bisa!",
          },
        ],
      },
    ],
  },
];
