import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imagePlayButton from "src/assets/Iconography/Solid/play-button.png";

export function CardItem({ item }) {
  const navigate = useNavigate();

  return (
    <div className="py-2 border-b-2 border-neutral-300">
      <button
        className="w-full flex gap-4"
        onClick={() => {
          navigate(
            `/${item.type == "video" ? "video" : "artikel"}/${item._id}`
          );
        }}
      >
        {item.type == "video" ? (
          <div className="w-fit relative flex justify-center items-center">
            <img
              src={item.thumbnail.default.url}
              className="w-[135px] h-[88px]"
            />
            <img src={imagePlayButton} className="absolute w-[32px] h-[32px]" />
          </div>
        ) : (
          <div className="w-fit relative flex justify-center items-center">
            <img src={item.thumbnail} className="w-[135px] h-[88px]" />
          </div>
        )}
        <div className="text-left w-fit">
          <h3 className="f-p1-sb line-clamp-1">{item.title}</h3>
          <p className="f-p2-r line-clamp-2 mt-1">{item.description}</p>
          <p className="f-p2-r text-neutral-800 line-clamp-1 mt-1">
            {item.created_at}
          </p>
        </div>
      </button>
    </div>
  );
}
