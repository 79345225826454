import { useContext, useEffect, useState } from "react";
import imageOpening from "src/assets/Illustration/Screening Welcome - Skrining Hero.svg";
import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { dataScreeningQuestions } from "src/data/screening";
import {
  QuestionChoice,
  QuestionChoiceImage,
  QuestionNumber,
} from "./section/question";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/context/UserContext";
import { UserServices } from "src/services/UserServices";
import imageLoading from "src/assets/Illustration/loading.svg";

export function ScreeningPage() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const userServices = new UserServices();

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [answers, setAnswers] = useState([]);

  return (
    <div className="w-full bg-white">
      <div className="container lg:pt-24 pt-8 mx-auto mb-8 min-h-screen lg:w-[480px] w-full flex flex-col items-center">
        {currentQuestion === 0 ? (
          <div className="flex flex-col items-center text-center w-11/12 lg:w-full pt-20">
            <h3 className="f-h3 mb-8">Skrining</h3>
            <img src={imageOpening} className="mb-8" />
            {user.screening.status_screening <= 2 ? (
              <p className="f-p1-r mb-4">
                Untuk mendapatkan rekomendasi konten yang sesuai dengan
                kebutuhanmu, disarankan untuk mengisi survey yang hanya memakan
                waktu sekitar 5 menit.
              </p>
            ) : null}

            <p className="f-p1-r mb-8">
              Skrining yang dilakukan meliputi:{" "}
              <span className="f-p1-sb">
                Usia, Status Gizi, dan Potensi Anemia
              </span>
            </p>
            <div className="mb-2 w-full">
              <ButtonPrimaryExpand
                text="Mulai Skrining"
                callback={() => {
                  setCurrentQuestion(1);
                }}
              />
            </div>
            {user.screening.status_screening == 1 ? (
              <div className="mb-2 w-full">
                <ButtonSecondaryExpand
                  text="Lewati"
                  callback={async () => {
                    const res = await userServices.updateScreening({
                      _id: user._id,
                      screening: {
                        status_screening: 2,
                        age: 0,
                        imt: 0,
                        blood_pressure: 0,
                      },
                    });
                    setUser({
                      ...user,
                      screening: { ...user.screening, status_screening: 2 },
                    });

                    navigate("/");
                  }}
                />
              </div>
            ) : (
              <div className="mb-2 w-full">
                <ButtonSecondaryExpand
                  text="Batal"
                  callback={() => {
                    navigate(-1);
                  }}
                />
              </div>
            )}
          </div>
        ) : null}

        <div className="w-11/12 lg:w-full">
          {dataScreeningQuestions.map((item, index) => {
            return item.type === "choice" ? (
              <QuestionChoice
                key={index}
                value={item}
                show={index == currentQuestion - 1}
                handlePrev={() => {
                  setCurrentQuestion(currentQuestion - 1);
                }}
                handleNext={() => {
                  setCurrentQuestion(currentQuestion + 1);
                }}
                index={index}
                callback={(value) => {
                  const newAnswers = [...answers];
                  newAnswers[index] = value;
                  setAnswers(newAnswers);
                }}
                selected={answers[index]}
              />
            ) : item.type === "number" ? (
              <QuestionNumber
                key={index}
                value={item}
                show={index == currentQuestion - 1}
                handlePrev={() => {
                  setCurrentQuestion(currentQuestion - 1);
                }}
                handleNext={() => {
                  setCurrentQuestion(currentQuestion + 1);
                }}
                index={index}
                callback={(value) => {
                  const newAnswers = [...answers];
                  newAnswers[index] = value;
                  setAnswers(newAnswers);
                }}
              />
            ) : item.type === "choice-image" ? (
              <QuestionChoiceImage
                key={index}
                value={item}
                show={index == currentQuestion - 1}
                handlePrev={() => {
                  setCurrentQuestion(currentQuestion - 1);
                }}
                handleNext={() => {
                  setCurrentQuestion(currentQuestion + 1);
                }}
                index={index}
                callback={(value) => {
                  const newAnswers = [...answers];
                  newAnswers[index] = value;
                  setAnswers(newAnswers);
                }}
                selected={answers[index]}
              />
            ) : null;
          })}

          {currentQuestion === dataScreeningQuestions.length + 1 ? (
            <LoadingResult
              data={answers}
              callback={async (screeningData) => {
                const res = await userServices.updateScreening({
                  _id: user._id,
                  screening: screeningData,
                });
                // navigate("/");
                window.location.replace("/");
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function LoadingResult({ data, callback }) {
  useEffect(() => {
    setTimeout(() => {
      const tempAnemia = data.slice(2, data.length - 1);

      const temp = {
        age: data[0],
        imt:
          parseInt(data[1].weight) /
          ((parseInt(data[1].height) / 100) * (parseInt(data[1].height) / 100)),
        blood_pressure: tempAnemia.filter((item) => item === 1).length,
        status_screening: 3,
      };

      callback(temp);
    }, 3000);
  }, []);

  return (
    <div className="text-center flex flex-col items-center">
      <h3 className="f-h3 mb-8">
        Menganalisa Hasil <br />
        Skriningmu
      </h3>
      <div className="mb-8">
        <img src={imageLoading} />
      </div>
      <p className="f-p1-r">
        Tenang, ini hanya memakan waktu <br /> beberapa detik
      </p>
    </div>
  );
}
