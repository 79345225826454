import imageLogo from "src/assets/logo.png";
import imageMenu from "src/assets/Iconography/Solid/menu.svg";
import imageClose from "src/assets/Iconography/Solid/Close Info Button.svg";
import imageExpandMore from "src/assets/Iconography/Solid/expand_more.svg";
import imageExpandLess from "src/assets/Iconography/Solid/expand_less.svg";
import { ButtonPrimary, ButtonWhite } from "../../../components/button";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "src/context/UserContext";

export function Navbar() {
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg fixed w-full top-7 flex justify-center z-40">
      {/* Desktop */}
      <div className="container w-11/12 rounded-full overflow-hidden bg-neutral-100 lg:flex hidden justify-between items-center py-3 px-5 shadow-s3">
        <div className="navigation flex items-center gap-10">
          <img src={imageLogo} className="w-20" />

          <div className="nav text-center">
            <ul className="flex items-center">
              <Link to={"/"}>
                <li className="mr-10">Beranda</li>
              </Link>
              <Link to={"/screening"}>
                <li className="mr-10">Skrining</li>
              </Link>
              <Link to="/edukasi">
                <li className="mr-10">Edukasi</li>
              </Link>
              <Link to="/tentang-kami">
                <li className="mr-10">Tentang Kami</li>
              </Link>
            </ul>
          </div>
        </div>

        <div className="action flex gap-1">
          <div>
            <ButtonWhite
              text={user ? user.name : "Masuk"}
              callback={() => {
                user ? navigate("/") : navigate("/masuk");
              }}
            />
          </div>
          <div>
            <ButtonPrimary
              text={user ? "Dashboard" : "Daftar"}
              callback={() => {
                user ? navigate("/") : navigate("/daftar");
              }}
            />
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div
        className={`container w-11/12 overflow-hidden bg-neutral-100 lg:hidden block py-3 px-5 shadow-s3 ${
          isMenuOpen ? "rounded-3xl" : "rounded-3xl"
        }`}
      >
        {/* Normal */}
        <div className="grid grid-cols-3">
          <button
            className="my-auto w-fit"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <img
              src={isMenuOpen ? imageClose : imageMenu}
              className="w-[18px]"
            />
          </button>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={imageLogo} className="w-[48px] m-auto" />
          </button>
          <div className="my-auto ml-auto">
            <ButtonWhite
              text={user ? "Dashboard" : "Masuk"}
              callback={() => {
                user ? navigate("/") : navigate("/masuk");
              }}
            />
          </div>
        </div>

        {/* Extend */}
        <div
          className={`mt-3 overflow-hidden duration-200 ${
            isMenuOpen ? "block" : "hidden"
          }`}
        >
          <ul className="flex flex-col gap-2 f-p1-m">
            <Link to="/">
              <li className="py-2">Beranda</li>
            </Link>
            <Link to="/screening">
              <li className="py-2">Skrining</li>
            </Link>
            <button
              onClick={() => {
                setIsSubMenuOpen(!isSubMenuOpen);
              }}
            >
              <li className="py-2 flex items-center justify-between">
                Edukasi{" "}
                <img src={isSubMenuOpen ? imageExpandLess : imageExpandMore} />
              </li>
            </button>
            <ul
              className={`flex-col gap-2 f-p1-r ${
                isSubMenuOpen ? "flex" : "hidden"
              }`}
            >
              <Link to="/rekomendasi">
                <li className="ml-5 py-2">Rekomendasi</li>
              </Link>
              <Link to="/edukasi">
                <li className="ml-5 py-2">Artikel/Video</li>
              </Link>
              <Link to="/game">
                <li className="ml-5 py-2">Games</li>
              </Link>
            </ul>
            <Link to="/tentang-kami">
              <li className="py-2">Tentang Kami</li>
            </Link>
            <li className="py-2">
              <ButtonPrimary
                text="Coba Sekarang"
                callback={() => {
                  navigate("/masuk");
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
