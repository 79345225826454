import imageLogo from "src/assets/logo.png";
import imageMenu from "src/assets/Iconography/Solid/menu.svg";
import imageClose from "src/assets/Iconography/Solid/Close Info Button.svg";
import imageExpandMore from "src/assets/Iconography/Solid/expand_more.svg";
import imageExpandLess from "src/assets/Iconography/Solid/expand_less.svg";
import { useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "src/context/UserContext";
import { SidebarContext } from "src/context/SidebarContext";
import { FiBell } from "react-icons/fi";

export function NavbarDashboard() {
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg fixed w-full flex justify-center z-40 shadow-s1 lg:px-10 px:6">
      {/* Desktop */}
      <div className="container w-full rounded-full overflow-hidden bg-neutral-100 lg:flex hidden justify-between items-center py-3 px-5 ">
        <div className="navigation flex items-center gap-10">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={imageLogo} className="w-20" />
          </button>

          <div className="nav text-center">
            <ul className="flex items-center">
              <Link to="/">
                <li className="mr-10">Beranda</li>
              </Link>
              <Link to="/screening">
                <li className="mr-10">Skrining</li>
              </Link>
              <Link to="/edukasi">
                <li className="mr-10">Edukasi</li>
              </Link>
              <Link to="/tentang-kami">
                <li className="mr-10">Tentang Kami</li>
              </Link>
            </ul>
          </div>
        </div>

        <div className="action flex gap-1">
          <button
            onClick={() => {
              navigate("/profile");
            }}
            className="shadow-s1 bg-neutral-200 rounded-full"
          >
            <img src={user.photo} className="w-[36px] rounded-full" />
          </button>
        </div>
      </div>

      {/* Mobile */}
      <div
        className={`container w-full overflow-hidden bg-neutral-100 lg:hidden block py-3 px-5`}
      >
        {/* Normal */}
        <div className="grid grid-cols-3">
          <button
            className="my-auto w-fit"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <img
              src={isMenuOpen ? imageClose : imageMenu}
              className="w-[18px]"
            />
          </button>
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={imageLogo} className="w-[48px] m-auto" />
          </button>
          <div className="my-auto ml-auto flex items-center">
            <button
              onClick={() => {
                navigate("/profile");
              }}
              className="shadow-s1 bg-neutral-200 rounded-full"
            >
              <img src={user.photo} className="w-[36px] rounded-full" />
            </button>
          </div>
        </div>

        {/* Extend */}
        <div
          className={`mt-3 overflow-hidden duration-200 ${
            isMenuOpen ? "block" : "hidden"
          }`}
        >
          <ul className="flex flex-col gap-2 f-p1-m">
            <Link to="/">
              <li className="py-2">Beranda</li>
            </Link>
            <Link to="/screening">
              <li className="py-2">Skrining</li>
            </Link>
            <button
              onClick={() => {
                setIsSubMenuOpen(!isSubMenuOpen);
              }}
            >
              <li className="py-2 flex items-center justify-between">
                Edukasi{" "}
                <img src={isSubMenuOpen ? imageExpandLess : imageExpandMore} />
              </li>
            </button>
            <ul
              className={`flex-col gap-2 f-p1-r ${
                isSubMenuOpen ? "flex" : "hidden"
              }`}
            >
              <Link to="/rekomendasi">
                <li className="ml-5 py-2">Rekomendasi</li>
              </Link>
              <Link to="/edukasi">
                <li className="ml-5 py-2">Artikel/Video</li>
              </Link>
              <Link to="/game">
                <li className="ml-5 py-2">Games</li>
              </Link>
            </ul>
            <Link to="/tentang-kami">
              <li className="py-2">Tentang Kami</li>
            </Link>
            <li className="py-2"></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export function NavbarAdminComponent() {
  const { user, setUser } = useContext(UserContext);
  const { sidebar, setSidebar } = useContext(SidebarContext);

  return (
    <div className="fixed z-40 md:w-full md:top-0 md:z-20 flex flex-row flex-wrap items-center bg-white h-20 w-full px-6 shadow-sm">
      <div className="flex-none lg:w-56 w-full flex flex-row items-center justify-between lg:justify-start">
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 mr-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={() => setSidebar(!sidebar)}
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ffillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <Link to="/">
          <a className="flex items-center text-2xl font-semibold text-gray-900">
            <img className="h-8 mr-2" src={imageLogo} alt="logo" />
          </a>
        </Link>

        <button
          id="sliderBtn"
          className="flex-none text-right text-gray-900 hidden md:block"
        >
          <i className="fad fa-list-ul"></i>
        </button>
      </div>

      <button
        id="navbarToggle"
        className="hidden md:block md:fixed right-0 mr-6"
      >
        <i className="fad fa-chevron-double-down"></i>
      </button>

      <div className="hidden xl:flex space-x-5 items-center ml-auto text-dark">
        <button className="flex items-center hover:text-gray-600" href="#">
          <FiBell className="text-xl" />
          <span className="flex absolute -mt-5 ml-4">
            <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-primary-main opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-primary-main"></span>
          </span>
        </button>
        <Link to={"/profile"}>
          <div className="flex items-center hover:text-gray-600">
            <span className="mr-2">{user.name}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 hover:text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
}
