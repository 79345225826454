import img1 from "src/assets/Screening/potensi-anemia/1.png";
import img2 from "src/assets/Screening/potensi-anemia/2.png";
import img3 from "src/assets/Screening/potensi-anemia/3.png";
import img4 from "src/assets/Screening/potensi-anemia/4.png";
import img5 from "src/assets/Screening/potensi-anemia/5.png";
import img6 from "src/assets/Screening/potensi-anemia/6.png";
import img7 from "src/assets/Screening/potensi-anemia/7.png";
import img8 from "src/assets/Screening/potensi-anemia/8.png";
import img9 from "src/assets/Screening/potensi-anemia/9.png";
import img10 from "src/assets/Screening/potensi-anemia/10.png";
import img11 from "src/assets/Screening/potensi-anemia/11.png";

export const dataScreeningQuestions = [
  {
    group: "Usia",
    question: "Berapa usia kamu saat ini?",
    type: "choice",
    choices: [
      {
        label: "10-13 tahun",
        value: "10-13 tahun",
      },
      {
        label: "14-17 tahun",
        value: "14-17 tahun",
      },
      {
        label: "18-24 tahun",
        value: "18-24 tahun",
      },
    ],
  },
  {
    group: "Status Gizi",
    question_1: "Berapa berat badanmu saat ini?",
    question_2: "Berapa tinggi badanmu saat ini?",
    type: "number",
  },
  {
    id: 1,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value: "Apakah Kamu sering merasa lelah, letih, atau lesu?",
      },
      {
        type: "image",
        value: img1,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 2,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value:
          "Apakah beberapa bagian kulit Kamu terlihat lebih pucat dari biasanya? (bibir, gusi, bagian dalam kelopak mata bawah, pangkal kuku, telapak tangan).",
      },
      {
        type: "image",
        value: img2,
      },
    ],
    choices: [
      {
        label: "Ya (Pucat)",
        value: 1,
      },
      {
        label: "Tidak (Normal)",
        value: 0,
      },
    ],
  },
  {
    id: 3,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value:
          "Apakah Kamu merasakan pusing berkunang-kunang dan lemas seperti mau jatuh saat melakukan aktivitas?",
      },
      {
        type: "image",
        value: img3,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 4,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value:
          "Apakah Kamu pernah mengalami BAB berdarah atau menstruasi berlebihan dalam 3 bulan terakhir?",
      },
      {
        type: "image",
        value: img4,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 5,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value:
          "Apakah Kamu merasakan sesak napas ketika melakukan salah satu kegiatan berikut (saat duduk/berbaring, melakukan pekerjaan rumah, naik turun tangga, mendaki, setelah berolahraga berat misalnya berlari)?",
      },
      {
        type: "image",
        value: img5,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 6,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value: "Apakah rambut Kamu mudah rontok?",
      },
      {
        type: "image",
        value: img6,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 7,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value: "Apakah Kamu mengalami kesulitan dalam berkonsentrasi?",
      },
      {
        type: "image",
        value: img7,
      },
      {
        type: "list",
        value: [
          "Perhatian mudah teralihkan",
          "Memulai kegiatan, tetapi tidak menyelesaikannya",
          "Tidak melanjutkan kembali kegiatan setelah adanya gangguan",
          "Kesulitan mengikuti alur pembicaraan",
          "Kesulitan mengikuti petunjuk atau arahan",
          "Mulai mengerjakan hal lain di tengah-tengah pembicaraan atau kegiatan",
          "Kehilangan minat menonton sebelum program TV atau film selesai",
          "Bengong atau menatap kosong untuk beberapa lama",
        ],
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 8,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value: "Apakah Kamu pernah didiagnosis anemia atau kurang darah?",
      },
      {
        type: "image",
        value: img8,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 9,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value:
          "Apakah Kamu jarang mengkonsumsi daging tanpa lemak? Misalnya: sapi, kambing, domba atau sayuran hijau contohnya bayam.",
      },
      {
        type: "text",
        value: "(Dikatakan jarang apabila mengkonsumsi <3x seminggu).",
      },
      {
        type: "image",
        value: img9,
      },
    ],
    choices: [
      {
        label: "Ya (Jarang)",
        value: 1,
      },
      {
        label: "Tidak (Sering)",
        value: 0,
      },
    ],
  },
  {
    id: 10,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value: "Apakah Kamu sering minum teh sewaktu makan?",
      },
      {
        type: "text",
        value: "(Sering, jika mengkonsumsi >4x seminggu).",
      },
      {
        type: "image",
        value: img10,
      },
    ],
    choices: [
      {
        label: "Ya",
        value: 1,
      },
      {
        label: "Tidak",
        value: 0,
      },
    ],
  },
  {
    id: 11,
    group: "Potensi Anemia",
    type: "choice-image",
    question: [
      {
        type: "text",
        value:
          "Periksalah konjungtiva (bagian dalam kelopak mata Kamu), warna manakah yang paling sesuai untuk menggambarkan konjungtiva Kamu?",
      },
      {
        type: "image",
        value: img11,
      },
    ],
    choices: [
      {
        label: "Pucat",
        value: 1,
      },
      {
        label: "Merah Muda",
        value: 0,
      },
    ],
  },
];
