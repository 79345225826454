import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ForgotPasswordPage } from "src/pages/auth/forgot-password";
import { SignInPage } from "src/pages/auth/signin";
import { SignUpPage } from "src/pages/auth/signup";
import { LandingPage } from "src/pages/landing";

export default function AuthRouterPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} exact />
        <Route path="/masuk" element={<SignInPage />} />
        <Route path="/daftar" element={<SignUpPage />} />
        <Route path="/lupa-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<SignInPage />} exact />
        <Route
          path="/tentang-kami"
          element={<LandingPage subMenuPath="#tentang-kami" />}
          exact
        />
      </Routes>
    </BrowserRouter>
  );
}
