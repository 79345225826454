import axios from "axios";
import baseUrl from "src/config/Url";

export class VisitorServices {
  async getAll() {
    const res = await axios.get(`${baseUrl}/visitors`);
    return res.data;
  }

  async add(data) {
    const res = await axios.post(`${baseUrl}/visitor`, data);
    return res.data;
  }
}
