import { useNavigate } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary } from "src/components/button";
import { InputDefault, InputSelect, InputTextArea } from "src/components/input";

import "react-markdown-editor-lite/lib/index.css";
import { useContext, useState } from "react";
import { EducationServices } from "src/services/EducationServices";
import { LoadingContext } from "src/context/LoadingContext";

var youtubeThumbnail = require("youtube-thumbnail");

export function AdminCreateVideoPage() {
  const navigate = useNavigate();
  const educationServices = new EducationServices();

  const { loading, setLoading } = useContext(LoadingContext);

  const [newVideo, setNewVideo] = useState({
    title: "",
    description: "",
    category: "umum",
    sub_category: "1",
    link: "",
    type: "video",
    thumbnail: "",
  });

  const handleChange = (e) => {
    setNewVideo({
      ...newVideo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const thumbnail = youtubeThumbnail(newVideo.link);

    const category = newVideo.category.toLowerCase();
    const temp = { ...newVideo, thumbnail: thumbnail, category: category };
    const res = await educationServices.add(temp);

    setLoading(false);

    navigate("/video");
  };

  return (
    <div>
      <div className="flex justify-between items-center my-6">
        <h1 className="text-2xl font-semibold">Tambah Video</h1>
      </div>
      <div className=" w-full lg:p-12 md:p-8 p-6 bg-white mt-5 rounded-lg shadow-lg">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <InputDefault
              name="title"
              label="Judul Video"
              value={newVideo.title}
              placeholder="Judul Video"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <InputTextArea
              name="description"
              label="Deskripsi Video"
              value={newVideo.description}
              placeholder="Deskripsi"
              onChange={handleChange}
            />
          </div>

          <div className="mb-6">
            <InputSelect
              label="Kategori"
              data={["umum", "age", "imt", "blood_pressure"]}
              dataLabel={["Umum", "Usia", "IMT", "Anemia"]}
              value={newVideo.category}
              onChange={handleChange}
              name={"category"}
            />
          </div>

          {newVideo.category.toLocaleLowerCase() == "age" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Usia"
                data={[1, 2, 3]}
                dataLabel={["10 - 13 tahun", "14 - 17 tahun", "18 - 24 tahun"]}
                value={newVideo.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : newVideo.category.toLocaleLowerCase() == "imt" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori IMT"
                data={[1, 2, 3, 4]}
                dataLabel={[
                  "Berat badan kurang (Underweight)",
                  "Berat badan ideal (Normal)",
                  "Berat badan berlebih (Overweight)",
                  "Obesitas",
                ]}
                value={newVideo.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : newVideo.category.toLocaleLowerCase() == "blood_pressure" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Tekanan Darah"
                data={[1, 2]}
                dataLabel={["Normal", "Anemia"]}
                value={newVideo.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : null}

          <div className="mb-4">
            <InputDefault
              name="link"
              label="URL Video"
              value={newVideo.link}
              placeholder="https://youtube.com"
              onChange={handleChange}
            />
          </div>

          <div className="flex gap-2 mt-6">
            <div>
              <ButtonPrimary text="Tambah" type="submit" callback={() => {}} />
            </div>
            <div>
              <ButtonSecondary
                text="Batal"
                callback={() => {
                  navigate("/video");
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
