import { Card, Typography } from "@material-tailwind/react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Loader } from "src/components/Loader";
import { BadgePrimary, BadgeSecondary } from "src/components/badge";
import { ButtonPrimary } from "src/components/button";
import { LoadingContext } from "src/context/LoadingContext";
import { EducationServices } from "src/services/EducationServices";

const TABLE_HEAD = ["No", "Judul", "Deskripsi", "Tanggal Dibuat", "Aksi"];

export function AdminArtikelPage() {
  const navigate = useNavigate();
  const educationServices = new EducationServices();

  const { loading, setLoading } = useContext(LoadingContext);

  const { isLoading, error, data } = useQuery(
    "articles",
    async () => await educationServices.getAllByType({ type: "article" })
  );

  if (isLoading || loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center my-6">
        <h1 className="text-2xl font-semibold">Semua Artikel</h1>
        <ButtonPrimary
          text="Tambah Artikel"
          callback={() => {
            navigate("/artikel/tambah");
          }}
        />
      </div>
      <Card className="overflow-scroll h-full w-full">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(({ _id, title, description, created_at }, index) => {
              const isLast = index === data.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={title}>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {index + 1}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {title}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {description}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {new Date(created_at).toLocaleString("id-ID")}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <div className="flex gap-2">
                      <BadgePrimary
                        text="Ubah"
                        callback={() => {
                          navigate(`/artikel/${_id}/ubah`);
                        }}
                      />
                      <BadgeSecondary
                        text="Hapus"
                        callback={async () => {
                          setLoading(true);
                          const res = await educationServices.delete(_id);
                          setLoading(false);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
