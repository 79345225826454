import imageLogo2 from "src/assets/Illustration/Logo.svg";
import imageMail from "src/assets/Iconography/Solid/mail.svg";
import imageUser from "src/assets/Iconography/Solid/User.svg";
import imageVisibility from "src/assets/Iconography/Solid/visibility.svg";
import imageEllipse from "src/assets/Illustration/Ellipse 4.png";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { AuthServices } from "src/services/AuthServices";
import { UserServices } from "src/services/UserServices";
import { LoadingContext } from "src/context/LoadingContext";
import { Loading } from "src/components/Loader";

export function SignUpPage() {
  const navigate = useNavigate();
  const authServices = new AuthServices();
  const userServices = new UserServices();

  const { loading, setLoading } = useContext(LoadingContext);

  const [stage, setStage] = useState(1);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmationPasswordVisible, setIsConfirmationPasswordVisible] =
    useState(false);

  // const [errorInput, setErrorInput] = useState({ email: "Not Null" });
  const [errorInput, setErrorInput] = useState(null);

  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    confirmation: "",
    name: "",
    address: "",
    gender: "",
    quiz: {
      true: 0,
      false: 0,
    },
  });

  const handleChange = (event) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value,
    });
    const temp = { ...newUser, [event.target.name]: event.target.value };

    if (stage == 1) validationStage1(temp);
    else if (stage == 2) validationStage2(temp);
  };

  const validationStage1 = (dataUser) => {
    setErrorInput(null);

    if (dataUser.email == "") {
      setErrorInput({ email: "Email tidak boleh kosong" });
      return false;
    }

    if (dataUser.password == "") {
      setErrorInput({ password: "Password tidak boleh kosong" });
      return false;
    } else if (dataUser.password.length < 8) {
      setErrorInput({ password: "Password minimal 8 karakter" });
      return false;
    }

    if (dataUser.confirmation == "") {
      setErrorInput({ confirmation: "Konfirmasi password tidak boleh kosong" });
      return false;
    }

    if (dataUser.password != dataUser.confirmation) {
      setErrorInput({ confirmation: "Konfirmasi password tidak sama" });
      return false;
    }
  };

  const validationStage2 = (dataUser) => {
    setErrorInput(null);

    if (dataUser.name == "") {
      setErrorInput({ name: "Nama tidak boleh kosong" });
      return false;
    }

    if (dataUser.address == "") {
      setErrorInput({ address: "Alamat tidak boleh kosong" });
      return false;
    }

    if (dataUser.gender == "") {
      setErrorInput({ gender: "Jenis Kelamin tidak boleh kosong" });
      return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await authServices.register(newUser.email, newUser.password);

    if (res == false) {
    } else {
      const temp = {
        name: newUser.name,
        email: newUser.email,
        role: "user",
        photo:
          "https://firebasestorage.googleapis.com/v0/b/reaksi-skrining.appspot.com/o/icon%2Favatar.svg?alt=media&token=8ceefa68-3876-4b47-9b20-146339b2f325",
        gender: newUser.gender,
        screening: {
          status_screening: 1,
          age: 0,
          imt: 0,
          blood_pressure: 0,
        },
        uid: res.user.uid,
        address: newUser.address,
        quiz: {
          true: 0,
          false: 0,
        },
      };
      const resRegister = await userServices.add(temp);
      if (resRegister == false) {
      } else {
        setLoading(false);
        navigate("/screening");
      }
    }
    setLoading(false);
  };

  return (
    <div className="w-full lg:min-h-screen bg-primary-surface flex justify-center items-center relative overflow-hidden">
      <Loading loading={loading} />
      <img src={imageEllipse} className="absolute w-full top-72" />
      <div className="container lg:w-[480px] w-full bg-white shadow-s3 lg:rounded-2xl p-6 relative">
        <div className="lg:mt-0 mt-16 flex justify-center">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={imageLogo2} className="" />
          </button>
        </div>
        <h1 className="f-h3 text-neutral-1000 text-center mt-5">
          Daftar ke Reaksi
        </h1>

        <div className="flex w-full mt-4 gap-1">
          <div className="w-full h-[4px] rounded-full bg-primary-main"></div>
          <div
            className={`w-full h-[4px] rounded-full ${
              stage == 1 ? "bg-primary-border" : "bg-primary-main"
            }`}
          ></div>
        </div>

        <form className="mt-4" onSubmit={handleSubmit}>
          {stage == 1 ? (
            <div>
              <div className="mt-4 relative">
                <label className="f-p1-m text-neutral-1000">Email</label>
                <img
                  src={imageMail}
                  className="absolute w-6 top-[40px] left-3"
                />
                <input
                  name="email"
                  onChange={handleChange}
                  value={newUser.email}
                  placeholder="Masukkan email aktif"
                  className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg pl-11 px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
                />
                <p className="f-p2-r text-danger-main">
                  {errorInput?.email ?? ""}
                </p>
              </div>
              <div className="mt-4 relative">
                <label className="f-p1-m text-neutral-1000">Password</label>
                <button
                  type="button"
                  placeholder="Masukkan password"
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                  className="absolute top-[40px] right-3"
                >
                  <img src={imageVisibility} className="w-6" />
                </button>
                <input
                  name="password"
                  onChange={handleChange}
                  value={newUser.password}
                  placeholder="******"
                  type={isPasswordVisible ? "text" : "password"}
                  className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
                />
                <p className="f-p2-r text-danger-main">
                  {errorInput?.password ?? ""}
                </p>
              </div>
              <div className="mt-4 relative">
                <label className="f-p1-m text-neutral-1000">Konfirmasi</label>
                <button
                  type="button"
                  placeholder="Masukkan konfirmasi password"
                  onClick={() => {
                    setIsConfirmationPasswordVisible(
                      !isConfirmationPasswordVisible
                    );
                  }}
                  className="absolute top-[40px] right-3"
                >
                  <img src={imageVisibility} className="w-6" />
                </button>
                <input
                  name="confirmation"
                  onChange={handleChange}
                  value={newUser.confirmation}
                  placeholder="******"
                  type={isConfirmationPasswordVisible ? "text" : "password"}
                  className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
                />
                <p className="f-p2-r text-danger-main">
                  {errorInput?.confirmation ?? ""}
                </p>
              </div>
              {errorInput == null ? (
                <div className="mt-4">
                  <ButtonPrimaryExpand
                    text="Lanjutkan"
                    callback={() => {
                      setStage(2);
                    }}
                    type="button"
                  />
                </div>
              ) : (
                <div className="mt-4">
                  <ButtonSecondaryExpand
                    text="Lanjutkan"
                    type="button"
                    callback={() => {}}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="mt-4 relative">
                <label className="f-p1-m text-neutral-1000">Nama Lengkap</label>
                <img
                  src={imageUser}
                  className="absolute w-5 top-[40px] left-3"
                />
                <input
                  name="name"
                  onChange={handleChange}
                  value={newUser.name}
                  placeholder="Masukkan nama lengkap"
                  className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg pl-11 px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
                />
                <p className="f-p2-r text-danger-main">
                  {errorInput?.name ?? ""}
                </p>
              </div>
              <div className="mt-4 relative">
                <label className="f-p1-m text-neutral-1000">Alamat</label>
                <textarea
                  name="address"
                  onChange={handleChange}
                  value={newUser.address}
                  placeholder="Masukkan alamat"
                  rows={4}
                  className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
                />
                <p className="f-p2-r text-danger-main">
                  {errorInput?.address ?? ""}
                </p>
              </div>
              <div className="mt-4 relative">
                <label className="f-p1-m text-neutral-1000">
                  Jenis Kelamin
                </label>

                <div className="flex items-center gap-2 mt-2">
                  <button
                    onClick={() => {
                      setNewUser({
                        ...newUser,
                        gender: "Laki-laki",
                      });
                      const temp = { ...newUser, gender: "Laki-laki" };
                      validationStage2(temp);
                    }}
                    type="button"
                    className={`py-3 px-5 w-full rounded-lg border-2 focus:bg-primary-border focus:border-primary-main ${
                      newUser?.gender == "Laki-laki"
                        ? "border-primary-main bg-primary-border"
                        : "border-transparent bg-neutral-200"
                    }`}
                  >
                    Laki-Laki
                  </button>
                  <button
                    onClick={() => {
                      setNewUser({
                        ...newUser,
                        gender: "Perempuan",
                      });
                      const temp = { ...newUser, gender: "Perempuan" };
                      validationStage2(temp);
                    }}
                    type="button"
                    className={`py-3 px-5 w-full rounded-lg border-2 focus:bg-primary-border focus:border-primary-main ${
                      newUser?.gender == "Perempuan"
                        ? "border-primary-main bg-primary-border"
                        : "border-transparent bg-neutral-200"
                    }`}
                  >
                    Perempuan
                  </button>
                </div>
                <p className="f-p2-r text-danger-main">
                  {errorInput?.gender ?? ""}
                </p>
              </div>

              {errorInput == null ? (
                <div className="mt-4">
                  <ButtonPrimaryExpand
                    type="button"
                    text="Daftar"
                    callback={() => {
                      handleSubmit();
                    }}
                  />
                </div>
              ) : (
                <div className="mt-4">
                  <ButtonSecondaryExpand
                    text="Daftar"
                    type="button"
                    callback={() => {}}
                  />
                </div>
              )}
            </div>
          )}

          <div className="mt-4">
            <div className="flex items-center justify-center gap-2">
              <label className="f-p1-r text-neutral-1000">
                Sudah punya akun?
              </label>
              <button
                type="button"
                onClick={() => {
                  navigate("/masuk");
                }}
                className="f-p1-r text-primary-main"
              >
                Masuk
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
