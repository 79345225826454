import iconCheck from "src/assets/Iconography/Outline/Check.svg";
import iconWrong from "src/assets/Iconography/Outline/Wrong.svg";
import { ButtonPrimaryExpand } from "src/components/button";

export function PopUpAnswer({ value, answer, callback }) {
  return (
    <div className="w-11/12 lg:w-[480px] h-fit bg-white rounded-[8px] p-8 flex-col">
      <h1 className="f-h3 text-center">
        Jawaban Kamu {value.key == answer ? "" : "Kurang"} Tepat!
      </h1>

      <div className="mt-4 flex justify-center">
        <img src={value.key == answer ? iconCheck : iconWrong} />
      </div>

      <div className="mt-4">
        <p className="f-p1-sb">Penjelasan</p>
        <p className="f-p1-r">{value.reason}</p>
      </div>

      <div className="mt-4">
        <ButtonPrimaryExpand text="Lanjut" callback={callback} />
      </div>
    </div>
  );
}
