import imageLeft from "src/assets/Iconography/Solid/arrow-left.svg";

export function NavQuestion({ value, handlePrev }) {
  return (
    <div className="flex items-center gap-2">
      <button
        type="button"
        onClick={() => {
          handlePrev();
        }}
        className="flex gap-2 items-center"
      >
        <div>
          <img src={imageLeft} className="w-10" />
        </div>
        <div>
          <p className="f-p1-m">Kembali</p>
        </div>
      </button>
      <div className="relative w-full">
        <div className="bg-primary-border absolute w-full h-1 rounded-full"></div>
        <div
          className="bg-primaryMain absolute h-1 rounded-full"
          style={{ width: `${value}` }}
        ></div>
      </div>
    </div>
  );
}
