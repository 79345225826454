import {
  ButtonPrimaryExpand,
  ButtonSecondary,
  ButtonSecondaryIcon,
} from "src/components/button";
import { Footer } from "src/components/footer";
import { NavbarDashboard } from "src/components/navbar";
import imageEdit from "src/assets/Iconography/Solid/pencil.svg";
import imageSearch from "src/assets/Iconography/Solid/document-search.svg";
import { UserContext } from "src/context/UserContext";
import { useContext } from "react";
import { CardScreeningResult } from "../user-dashboard/component/card-screening-result";
import { useNavigate } from "react-router-dom";
import { AuthServices } from "src/services/AuthServices";

export function UserProfilePage() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const authServices = new AuthServices();

  return (
    <div className="">
      <NavbarDashboard />
      <div className="container lg:w-10/12 w-11/12 lg:pt-28 pt-24 mx-auto mb-8 min-h-[600px]">
        <div className="grid grid-cols-12 gap-6 lg:w-[900px] mx-auto">
          <div className="lg:col-span-6 col-span-12">
            <div className="flex justify-between items-center mb-4">
              <h3 className="f-h3">Profil</h3>
              <div>
                <ButtonSecondaryIcon
                  text="Edit Profile"
                  image={imageEdit}
                  callback={() => {
                    navigate("/edit-profile");
                  }}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-4">
              <div className="rounded-circle">
                <img src={user.photo} className="rounded-full w-20 h-20" />
              </div>
              <div className="">
                <h4 className="f-h4">{user.name}</h4>
                <p className="f-p1-r">{user.gender}</p>
              </div>
            </div>
            <div className="mb-3">
              <p className="f-p1-sb">Email</p>
              <p className="f-p1-r">{user.email}</p>
            </div>
            <div className="mb-3">
              <p className="f-p1-sb">Alamat</p>
              <p className="f-p1-r">{user.address}</p>
            </div>
            <div className="mb-3 mt-6 flex gap-4">
              <ButtonSecondary
                text="Log Out"
                callback={async () => {
                  try {
                    const res = await authServices.logout();
                    setUser(null);
                    navigate("/masuk");
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />

              <ButtonSecondary
                text="Ganti Password"
                callback={() => {
                  navigate("/ganti-password");
                }}
              />
            </div>
          </div>
          <div className="col-span-1 lg:block hidden">
            <div className="h-full mx-auto w-0.5 bg-neutral-500"></div>
          </div>
          <div className="lg:col-span-5 col-span-12">
            <div className="flex justify-between items-center mb-4">
              <h3 className="f-h3">Skrining</h3>
              {user.screening.status_screening > 2 ? (
                <div>
                  <ButtonSecondaryIcon
                    text="Skrining Ulang"
                    image={imageSearch}
                    callback={() => {
                      navigate("/screening");
                    }}
                  />
                </div>
              ) : null}
            </div>

            <div className="">
              {user.screening.status_screening <= 2 ? (
                <div className="mt-4 grid grid-cols-1 gap-3 text-center">
                  <p className="f-p1-sb">Belum melakukan skrining</p>
                  <p className="f-p2-r">
                    Kamu perlu melakukan skrining terlebih dahulu untuk
                    mendapatkan informasi{" "}
                    <span className="f-p2-sb">
                      kategori usia, status gizi, dan potensi anemia
                    </span>
                  </p>
                  <ButtonPrimaryExpand
                    text="Mulai Skrining"
                    callback={() => {
                      navigate("/screening");
                    }}
                  />
                </div>
              ) : (
                <div className="mt-4 grid grid-cols-1 gap-3">
                  <CardScreeningResult
                    title="Kategori Usia"
                    description={
                      user.screening.age == 1
                        ? "Remaja Awal (10-13 tahun)"
                        : user.screening.age == 2
                        ? "Remaja Tengah (14-17 tahun)"
                        : user.screening.age == 3
                        ? "Remaja Akhir (18-24 tahun)"
                        : "-"
                    }
                    type="age"
                    callback={(type) => {
                      // setScreeningResult("tahap-remaja");
                    }}
                  />
                  <CardScreeningResult
                    title="Indeks Massa Tubuh"
                    description={
                      user.screening.imt < 18.5
                        ? "Underweight (<18)"
                        : user.screening.imt >= 18.5 && user.screening.imt < 25
                        ? "Normal (18.5-24.9)"
                        : user.screening.imt >= 25.0 && user.screening.imt < 30
                        ? "Overweight (25-29.9)"
                        : user.screening.imt >= 30
                        ? "Obesitas (>=30)"
                        : "-"
                    }
                    type="imt"
                    callback={(type) => {
                      // setScreeningResult("status-gizi");
                    }}
                  />
                  <CardScreeningResult
                    title="Potensi Anemia"
                    description={
                      user.screening.blood_pressure >= 5
                        ? "Berpotensi Anemia"
                        : "Normal"
                    }
                    type="blood-pressure"
                    callback={(type) => {
                      // setScreeningResult("potensi-anemia");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
