import { ButtonPrimary } from "../../components/button";
import { CardCreator, CardLarge, CardSmall } from "./component/card";
import { Navbar } from "./component/navbar";
import imageHero from "src/assets/Illustration/Landing Page - Hero Image.svg";
import imageRekomendasi from "src/assets/Illustration/Landing Page - Rekomendasi.svg";
import imageEdukasi from "src/assets/Illustration/Landing Page - Edukasi.svg";
import imageGames from "src/assets/Illustration/Landing Page - Games.svg";
import imageCheckCircle from "src/assets/Iconography/Outline/check-circle.svg";
import imageLightBulb from "src/assets/Iconography/Outline/light-bulb.svg";
import imageLockClosed from "src/assets/Iconography/Outline/lock-closed.svg";
import imageEllipse from "src/assets/Illustration/Ellipse 4.png";
import imageCreator1 from "src/assets/Photo/Photo 1.png";
import imageCreator2 from "src/assets/Photo/Photo 2.png";
import imageCreator3 from "src/assets/Photo/Photo 3.png";
import imageCreator4 from "src/assets/Photo/Photo 4.png";
import imageCreator5 from "src/assets/Photo/Photo 5.png";
import imageInstagram from "src/assets/Iconography/Outline/Instagram.svg";
import imageMail from "src/assets/Iconography/Outline/mail.svg";
import { Footer } from "src/components/footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { VisitorServices } from "src/services/VisitorServices";
import { useQuery } from "react-query";
import { Loader } from "src/components/Loader";
import { Visitor } from "./component/visitor";

export function LandingPage({ subMenuPath }) {
  const navigate = useNavigate();

  const visitorServices = new VisitorServices();

  const [showVisitor, setShowVisitor] = useState(true);

  const { isLoading, error, data } = useQuery(
    "getVisitor",
    async () => await visitorServices.getAll()
  );

  useEffect(() => {
    if (subMenuPath == "#tentang-kami") {
      window.scrollTo(0, 800);
    } else {
      window.scrollTo(0, 0);
    }
  }, [subMenuPath]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="f-h3">{error}</h3>;
  }

  return (
    <div className="bg-primary-surface relative">
      <Navbar />
      {showVisitor ? (
        <div className="fixed bottom-10 right-10 z-40">
          <Visitor
            total={data.total}
            daily={data.daily}
            monthly={data.monthly}
            callback={() => {
              setShowVisitor(false);
            }}
          />
        </div>
      ) : null}

      {/* Hero Section */}
      <section className="flex justify-center lg:min-h-screen relative z-10">
        <div className="container w-11/12 text-center">
          <h1 className="f-h1 text-neutral-1000 mt-36">
            Karena Kesehatan <br></br> Reproduksimu Penting
          </h1>
          <p className="f-p1-r text-neutral-900 mt-4">
            Temukan rekomendasi yang tepat <br></br> dan terpercaya sesuai
            dengan kebutuhanmu
          </p>

          <div className="mt-8">
            <ButtonPrimary
              text="Coba Sekarang"
              callback={() => {
                navigate("/masuk");
              }}
            />
          </div>

          <div className="flex justify-center mt-8">
            <img src={imageHero} />
          </div>

          <div className="flex justify-center">
            <div className="bg-neutral-100 rounded-xl py-4 px-5 relative -top-10 lg:w-2/3 shadow-s3">
              <h3 className="f-h3 text-neutral-1000">Apa itu Reaksi?</h3>
              <p className="f-p1-r text-neutral-900 mt-3">
                Hay Gaess, Reaksi adalah aplikasi skrining dan edukasi tentang
                kesehatan reproduksi remaja yang kami persembahkan untuk kalian
                remaja di Indonesia, agar kita bisa belajar bersama dengan cara
                yg mudah dan menyenangkan... Soooo... Enjoy it.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Apa yang bisa dan kenapa harus */}
      <section className="flex flex-col items-center lg:min-h-screen relative py-10 lg:py-0">
        <div className="absolute -top-40 z-0">
          <img src={imageEllipse} className=" h-[972px]" />
        </div>

        <div className="container w-11/12 text-center relative">
          <h1 className="lg:f-h1 f-h3 text-neutral-1000 mt-16 text-center">
            Apa yang bisa <br></br>
            kamu dapatkan dari Reaksi?
          </h1>

          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-8 gap-4 mt-8 lg:w-2/3 mx-auto">
            <CardLarge
              image={imageRekomendasi}
              title="Rekomendasi"
              description="Dapatkan rekomendasi artikel dan video sesuai kondisimu."
            />
            <CardLarge
              image={imageEdukasi}
              title="Edukasi"
              description="Perkaya pengetahuan melalui artikel maupun video pilihan."
            />
            <CardLarge
              image={imageGames}
              title="Games"
              description="Perdalam pengetahuan dengan cara yang menyenangkan."
            />
          </div>
        </div>

        <div className="container w-11/12 text-center relative">
          <h1 className="lg:f-h1 f-h3 text-neutral-1000 mt-16 text-center">
            Kenapa Harus <br></br>
            Menggunakan Reaksi?
          </h1>

          <div className="grid lg:grid-cols-3 grid-cols-2 lg:gap-8 gap-6 mt-8 lg:w-2/3 mx-auto">
            <CardSmall image={imageLightBulb} title="Rekomendasi Yang Akurat" />
            <CardSmall
              image={imageCheckCircle}
              title="Konten-konten Terpercaya"
            />
            <CardSmall
              image={imageLockClosed}
              title="Privasi Aman & Terjamin"
            />
          </div>
        </div>
      </section>

      <section className="flex flex-col items-center lg:min-h-screen relative overflow-hidden lg:bg-transparent bg-neutral-100 py-10 lg:py-0">
        <div className="absolute z-0 top-64">
          <img src={imageEllipse} className=" h-[972px]" />
        </div>

        <div className="container w-11/12 text-center relative">
          <h1 className="lg:f-h1 f-h3 text-neutral-1000 mt-24 text-center">
            Para Kreator Di Balik Reaksi
          </h1>

          <div className="grid lg:grid-cols-5 grid-cols-2 lg:gap-4 gap-2 mt-8 lg:w-3/4 mx-auto">
            <CardCreator
              image={imageCreator1}
              name="Lisa Purbawaning Wulandari., SKM., M.Kes"
            />
            <CardCreator
              image={imageCreator2}
              name="Dr. Anindya Hapsari, M.Kes"
            />
            <CardCreator
              image={imageCreator3}
              name="Duhita Dyah Apsari, S.Keb.Bd, M.Kes"
            />
            <CardCreator image={imageCreator4} name="Vionna Fazrin Cahyani" />
            <CardCreator image={imageCreator5} name="Nadya Dina Tazkiyah" />
          </div>

          <div className="flex lg:justify-between flex-col lg:flex-row lg:w-3/4 mx-auto mt-16">
            <h1 className="lg:f-h1 f-h3 text-neutral-1000 text-center">
              Follow Kami
            </h1>

            <div className="flex lg:flex-row flex-col gap-4 lg:mt-0 mt-5">
              <a
                href="https://www.instagram.com/reaksikuy/"
                target="_blank"
                className="bg-primary-surface rounded-full lg:py-2 py-4 px-10 f-p1-m flex items-center justify-center gap-2"
              >
                <img src={imageInstagram} />
                @reaksikuy
              </a>
              <a
                href="mailto:reaksikuy@gmail.com"
                className="bg-primary-surface rounded-full lg:py-2 py-4 px-10 f-p1-m flex items-center justify-center gap-2"
              >
                <img src={imageMail} />
                reaksikuy@gmail.com
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}
