import { useNavigate, useParams } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary } from "src/components/button";
import { InputDefault, InputSelect, InputTextArea } from "src/components/input";

import "react-markdown-editor-lite/lib/index.css";
import { useContext, useState } from "react";
import { EducationServices } from "src/services/EducationServices";
import { LoadingContext } from "src/context/LoadingContext";
import { useQuery } from "react-query";
import { Loader, Loading } from "src/components/Loader";

export function AdminEditVideoPage() {
  const navigate = useNavigate();
  const educationServices = new EducationServices();

  const { id } = useParams();
  const { loading, setLoading } = useContext(LoadingContext);

  const [video, setVideo] = useState(null);

  const { isLoading, error, data } = useQuery("video", async () => {
    const res = await educationServices.getById(id);
    setVideo(res);
    return res;
  });

  const handleChange = (e) => {
    setVideo({
      ...video,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const category = video.category.toLowerCase();
    const temp = { ...video, category: category };
    const { _id, ...dataVideo } = temp;
    const res = await educationServices.update(_id, dataVideo);

    setLoading(false);

    navigate("/video");
  };

  if (isLoading || loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center my-6">
        <h1 className="text-2xl font-semibold">Ubah Video</h1>
      </div>
      <div className=" w-full lg:p-12 md:p-8 p-6 bg-white mt-5 rounded-lg shadow-lg">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <InputDefault
              name="title"
              label="Judul Artikel"
              value={video?.title}
              placeholder="Judul Artikel"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <InputTextArea
              name="description"
              label="Deskripsi Artikel"
              value={video?.description}
              placeholder="Deskripsi"
              onChange={handleChange}
            />
          </div>

          <div className="mb-6">
            <InputSelect
              label="Kategori"
              data={["umum", "age", "imt", "blood_pressure"]}
              dataLabel={["Umum", "Usia", "IMT", "Anemia"]}
              value={video.category}
              onChange={handleChange}
              name={"category"}
            />
          </div>

          {video.category.toLocaleLowerCase() == "age" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Usia"
                data={[1, 2, 3]}
                dataLabel={["10 - 13 tahun", "14 - 17 tahun", "18 - 24 tahun"]}
                value={video.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : video.category.toLocaleLowerCase() == "imt" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori IMT"
                data={[1, 2, 3, 4]}
                dataLabel={[
                  "Berat badan kurang (Underweight)",
                  "Berat badan ideal (Normal)",
                  "Berat badan berlebih (Overweight)",
                  "Obesitas",
                ]}
                value={video.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : video.category.toLocaleLowerCase() == "blood_pressure" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Tekanan Darah"
                data={[1, 2]}
                dataLabel={["Normal", "Anemia"]}
                value={video.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : null}

          <div className="mb-4">
            <InputDefault
              name="link"
              label="URL Video"
              value={video?.link}
              placeholder="https://youtube.com"
              onChange={handleChange}
            />
          </div>

          <div className="flex gap-2 mt-6">
            <div>
              <ButtonPrimary text="Ubah" type="submit" callback={() => {}} />
            </div>
            <div>
              <ButtonSecondary
                text="Batal"
                callback={() => {
                  navigate("/video");
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
