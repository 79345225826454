import imageLogo2 from "src/assets/Illustration/Logo.svg";
import imageMail from "src/assets/Iconography/Solid/mail.svg";
import imageVisibility from "src/assets/Iconography/Solid/visibility.svg";
import imageEllipse from "src/assets/Illustration/Ellipse 4.png";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { ButtonPrimaryExpand } from "src/components/button";
import { AuthServices } from "src/services/AuthServices";
import { UserServices } from "src/services/UserServices";
import { LoadingContext } from "src/context/LoadingContext";
import { UserContext } from "src/context/UserContext";
import { Error } from "./component/error";

export function SignInPage() {
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(LoadingContext);
  const { user, setUser } = useContext(UserContext);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const authServices = new AuthServices();
  const userServices = new UserServices();

  const handleChange = (event) => {
    setUserLogin({
      ...userLogin,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const res = await authServices.login({ ...userLogin });

    if (res.operationType == "signIn") {
      const resUser = await userServices.getUser(res.user.email);

      if (resUser != null) {
        setUser(resUser);
        setLoading(false);
        // navigate("/");
        window.location.replace("/");
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError({
        header: "Login gagal",
        body: res ?? "Terjadi kesalahan",
      });
    }
  };

  return (
    <div className="w-full lg:min-h-screen bg-primary-surface flex justify-center items-center relative overflow-hidden">
      {error != null ? (
        <Error
          message={error}
          handleClose={() => {
            setError(null);
          }}
        />
      ) : null}
      <img src={imageEllipse} className="absolute w-full top-72" />
      <div className="container lg:w-[480px] w-full bg-white shadow-s3 lg:rounded-2xl p-6 relative">
        <div className="lg:mt-0 mt-16 flex justify-center">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={imageLogo2} className="" />
          </button>
        </div>
        <h1 className="f-h3 text-neutral-1000 text-center mt-5">
          Masuk ke Reaksi
        </h1>

        <form className="mt-8" onSubmit={handleSubmit}>
          <div className="mt-4 relative">
            <label className="f-p1-m text-neutral-1000">Email</label>
            <img
              src={imageMail}
              className="absolute w-6 bottom-[11px] left-3"
            />
            <input
              onChange={handleChange}
              name="email"
              value={userLogin.email}
              placeholder="Email address"
              className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg pl-11 px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
            />
          </div>
          <div className="mt-4 relative">
            <div className="flex justify-between items-center">
              <label className="f-p1-m text-neutral-1000">Password</label>
              <button
                type="button"
                onClick={() => {
                  navigate("/lupa-password");
                }}
                className="f-p1-r text-primary-main"
              >
                Lupa password
              </button>
            </div>
            <button
              type="button"
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible);
              }}
              className="absolute bottom-[11px] right-3"
            >
              <img src={imageVisibility} className="w-6" />
            </button>
            <input
              onChange={handleChange}
              name="password"
              value={userLogin.password}
              placeholder=""
              type={isPasswordVisible ? "text" : "password"}
              className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
            />
          </div>
          <div className="mt-4">
            <ButtonPrimaryExpand
              text="Masuk"
              callback={() => {}}
              type="submit"
            />
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-center gap-2">
              <label className="f-p1-r text-neutral-1000">
                Belum punya akun?
              </label>
              <button
                type="button"
                onClick={() => {
                  navigate("/daftar");
                }}
                className="f-p1-r text-primary-main"
              >
                Daftar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
