import imageLogo from "src/assets/logo.png";
import imageLogoSvg from "src/assets/Illustration/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import imageInstagram from "src/assets/Iconography/Outline/Instagram.svg";
import imageMail from "src/assets/Iconography/Outline/mail.svg";

export function Footer() {
  return (
    <section className="flex flex-col items-center relative overflow-hidden border-t-2 border-neutral-300 bg-neutral-100 py-5 ">
      <div className="container w-11/12">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
          {/* Logo */}
          <div className="">
            <div className="flex items-center gap-2">
              <img src={imageLogoSvg} className="w-[22px]" />
              <img src={imageLogo} className="w-[48px]" />
            </div>
            <p className="f-p1-r lg:mt-2 mt-4">
              Remaja Awasi Kesehatan Reproduksi
            </p>
          </div>

          {/* Navigation */}
          <div className="">
            <p className="f-p1-m text-neutral-1000">Navigasi</p>
            <ul className="lg:mt-2 mt-6 flex lg:flex-row flex-col lg:gap-8 gap-6">
              <Link to="/">
                <li className="f-p1-r text-neutral-900">Beranda</li>
              </Link>
              <Link to="/rekomendasi">
                <li className="f-p1-r text-neutral-900">Rekomendasi</li>
              </Link>
              <Link to="/edukasi">
                <li className="f-p1-r text-neutral-900">Artikel / Video</li>
              </Link>
              <Link to="/game">
                <li className="f-p1-r text-neutral-900">Games</li>
              </Link>
              <Link to="/tentang-kami">
                <li className="f-p1-r text-neutral-900">Tentang</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="h-0.5 bg-neutral-300 w-full my-5"></div>
        <div className="flex justify-between">
          <div>
            <h4 className="f-p1-r text-neutral-900">2023 Reaksi</h4>
          </div>
          <div className="flex items-center gap-2">
            <img src={imageInstagram} />
            <a href="https://www.instagram.com/reaksikuy/" target="_blank">
              <h4 className="f-p1-r text-neutral-900">@reaksikuy</h4>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
