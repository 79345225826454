import imageDetailGreen from "src/assets/Iconography/Outline/Detail Button Green.svg";
import imageAge from "src/assets/Iconography/Solid/male.svg";
import imageIMT from "src/assets/Iconography/Solid/accessibility.svg";
import imageBloodPressure from "src/assets/Iconography/Solid/bloodtype.svg";

export function CardScreeningResult(props) {
  return (
    <button
      onClick={() => {
        props.callback(props.type);
      }}
    >
      <div
        className={`p-3 rounded-xl ${
          props.type == "age"
            ? "bg-success-border"
            : props.type == "imt"
            ? "bg-warning-border"
            : "bg-danger-border"
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center">
              <img
                src={
                  props.type == "age"
                    ? imageAge
                    : props.type == "imt"
                    ? imageIMT
                    : imageBloodPressure
                }
              />
              <h3
                className={`f-p2-m ${
                  props.type == "age"
                    ? "text-success-pressed"
                    : props.type == "imt"
                    ? "text-warning-pressed"
                    : "text-danger-pressed"
                } `}
              >
                {props.title}
              </h3>
            </div>
            <p
              className={`f-p1-sb text-left ${
                props.type == "age"
                  ? "text-success-pressed"
                  : props.type == "imt"
                  ? "text-warning-pressed"
                  : "text-danger-pressed"
              }`}
            >
              {props.description}
            </p>
          </div>
          <div>
            <img src={imageDetailGreen} />
          </div>
        </div>
      </div>
    </button>
  );
}

export function CardScreeningEmpty(props) {
  return (
    <div className={`p-3 rounded-xl bg-neutral-300`}>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          <div className="flex gap-2 items-center">
            <img
              src={
                props.type == "age"
                  ? imageAge
                  : props.type == "imt"
                  ? imageIMT
                  : imageBloodPressure
              }
            />
            <h3 className={`f-p2-m text-neutral-600`}>{props.title}</h3>
          </div>
          <p className={`f-p1-sb text-neutral-600`}>{props.description}</p>
        </div>
        <div>
          <img src={imageDetailGreen} />
        </div>
      </div>
    </div>
  );
}
