import { ButtonSecondary } from "src/components/button";
import { CardItem } from "../component/card-item";
import { EducationServices } from "src/services/EducationServices";
import { Loader } from "src/components/Loader";
import { useEffect, useState } from "react";

export function EdukasiSection() {
  const educationServices = new EducationServices();

  const [load, setLoad] = useState(true);
  const [agePageNumber, setAgePageNumber] = useState(1);
  const [imtPageNumber, setImtPageNumber] = useState(1);
  const [bloodPressurePageNumber, setBloodPressurePageNumber] = useState(1);
  const [generalPageNumber, setGeneralPageNumber] = useState(1);

  const [educationsAge, setEducationsAge] = useState([]);
  const [educationsImt, setEducationsImt] = useState([]);
  const [educationsBloodPressure, setEducationsBloodPressure] = useState([]);
  const [educationsGeneral, setEducationsGeneral] = useState([]);

  useEffect(() => {
    fetch({
      agePageNumber: agePageNumber,
      imtPageNumber: imtPageNumber,
      bloodPressurePageNumber: bloodPressurePageNumber,
      generalPageNumber: generalPageNumber,
    });
  }, []);

  async function fetch({
    agePageNumber,
    imtPageNumber,
    bloodPressurePageNumber,
    generalPageNumber,
  }) {
    fetchAge(agePageNumber);
    fetchImt(imtPageNumber);
    fetchBloodPressure(bloodPressurePageNumber);
    fetchGeneral(generalPageNumber);
    setLoad(false);
  }

  async function fetchAge(agePageNumber) {
    setLoad(true);
    const resEducationsAge = await educationServices.getAllByCategoryPagination(
      {
        category: "age",
        pageNumber: agePageNumber,
        pageSize: 3,
      }
    );
    const tempAge = [...educationsAge, ...resEducationsAge];
    setEducationsAge(tempAge);
    setLoad(false);
  }

  async function fetchImt(imtPageNumber) {
    setLoad(true);
    const resEducationsImt = await educationServices.getAllByCategoryPagination(
      {
        category: "imt",
        pageNumber: imtPageNumber,
        pageSize: 3,
      }
    );
    const tempImt = [...educationsImt, ...resEducationsImt];
    setEducationsImt(tempImt);
    setLoad(false);
  }

  async function fetchBloodPressure(bloodPressurePageNumber) {
    setLoad(true);
    const resEducationsBloodPressure =
      await educationServices.getAllByCategoryPagination({
        category: "blood_pressure",
        pageNumber: bloodPressurePageNumber,
        pageSize: 3,
      });

    const tempBloodPressure = [
      ...educationsBloodPressure,
      ...resEducationsBloodPressure,
    ];
    setEducationsBloodPressure(tempBloodPressure);
    setLoad(false);
  }

  async function fetchGeneral(generalPageNumber) {
    setLoad(true);
    const resEducationsGeneral =
      await educationServices.getAllByCategoryPagination({
        category: "umum",
        pageNumber: generalPageNumber,
        pageSize: 3,
      });

    const tempGeneral = [...educationsGeneral, ...resEducationsGeneral];
    setEducationsGeneral(tempGeneral);
    setLoad(false);
  }

  if (load) {
    return <Loader />;
  }

  return (
    <div className="w-full">
      <p className="f-p1-r mt-5">
        Perkaya pengetahuan kesehatan reproduksi melalui artikel maupun video
        pilihan
      </p>

      <div className="mt-4">
        <h4 className="f-h4 mb-1">Masa Remaja dan Perubahan Fisik</h4>
        {educationsAge?.map((item, index) => {
          return <CardItem key={index} item={item} />;
        })}
        <div className="flex justify-center mt-3">
          <ButtonSecondary
            text={"Muat lebih banyak"}
            callback={async () => {
              const pageNumber = agePageNumber + 1;
              await fetchAge(pageNumber);
              setAgePageNumber(pageNumber);
            }}
          />
        </div>
      </div>

      <div className="mt-4">
        <h4 className="f-h4 mb-1">Gizi Yang Baik Untuk Remaja</h4>
        {educationsImt?.map((item, index) => {
          return <CardItem key={index} item={item} />;
        })}
        <div className="flex justify-center mt-3">
          <ButtonSecondary
            text={"Muat lebih banyak"}
            callback={async () => {
              const pageNumber = imtPageNumber + 1;
              await fetchImt(pageNumber);
              setImtPageNumber(pageNumber);
            }}
          />
        </div>
      </div>

      <div className="mt-4">
        <h4 className="f-h4 mb-1">Anemia Pada Remaja</h4>
        {educationsBloodPressure?.map((item, index) => {
          return <CardItem key={index} item={item} />;
        })}
        <div className="flex justify-center mt-3">
          <ButtonSecondary
            text={"Muat lebih banyak"}
            callback={async () => {
              const pageNumber = bloodPressurePageNumber + 1;
              await fetchBloodPressure(pageNumber);
              setBloodPressurePageNumber(pageNumber);
            }}
          />
        </div>
      </div>

      <div className="mt-4">
        <h4 className="f-h4 mb-1">Edukasi untuk semua</h4>
        {educationsGeneral?.map((item, index) => {
          return <CardItem key={index} item={item} />;
        })}
        <div className="flex justify-center mt-3">
          <ButtonSecondary
            text={"Muat lebih banyak"}
            callback={async () => {
              const pageNumber = generalPageNumber + 1;
              await fetchGeneral(pageNumber);
              setGeneralPageNumber(pageNumber);
            }}
          />
        </div>
      </div>
    </div>
  );
}
