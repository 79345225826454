import { FiX } from "react-icons/fi";
import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";

export function PopUpResult({ image, handleTryAgin, handleHome }) {
  return (
    <div className="w-11/12 lg:w-[480px] h-[600px] relative overflow-y-auto bg-white rounded-[8px] p-8 flex-col">
      <button onClick={handleHome} className="absolute right-3 top-3">
        <FiX size={24} />
      </button>
      <h1 className="f-h3 text-center">
        Hore!!! <br /> Kamu Berhasil Menyusun Puzzle
      </h1>

      <div className="mt-4 flex justify-center">
        <img src={image} />
      </div>

      <div className="mt-4">
        <ButtonPrimaryExpand text="Main Lagi" callback={handleTryAgin} />
      </div>
      <div className="mt-4">
        <ButtonSecondaryExpand
          text="Kembali ke Beranda"
          callback={handleHome}
        />
      </div>
    </div>
  );
}
