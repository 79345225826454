import { useNavigate } from "react-router-dom";
import imagePlayButton from "src/assets/Iconography/Solid/play-button.png";

export function CardRecommendation({ item }) {
  const navigate = useNavigate();

  return (
    <div className="py-2 border-b-2 border-neutral-300">
      <button
        className="w-full grid grid-cols-12 gap-2"
        onClick={() => {
          navigate(
            `/${item.type == "video" ? "video" : "artikel"}/${item._id}`
          );
        }}
      >
        {item.type == "video" ? (
          <div className="col-span-3 flex justify-center items-center">
            <img
              src={item.thumbnail.default.url}
              className="w-[88px] h-[60px]"
            />
            <img src={imagePlayButton} className="absolute w-[32px] h-[32px]" />
          </div>
        ) : (
          <div className="col-span-3 flex justify-center items-center">
            <img src={item.thumbnail} className="w-[88px] h-[60px]" />
          </div>
        )}
        <div className="col-span-9 text-left">
          <h3 className="f-p1-sb line-clamp-2">{item.title}</h3>
          <p className="f-p2-r text-neutral-800 line-clamp-1 mt-1">
            {item.description}
          </p>
        </div>
      </button>
    </div>
  );
}
