import { useNavigate } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary } from "src/components/button";
import {
  InputDefault,
  InputFile,
  InputSelect,
  InputTextArea,
} from "src/components/input";

import { useContext, useState } from "react";
import FileServices from "src/services/FileServices";
import { EducationServices } from "src/services/EducationServices";
import { LoadingContext } from "src/context/LoadingContext";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function AdminCreateArtikelPage() {
  const navigate = useNavigate();
  const fileServices = new FileServices();
  const educationServices = new EducationServices();

  const { loading, setLoading } = useContext(LoadingContext);

  const [newArticle, setNewArticle] = useState({
    title: "",
    description: "",
    thumbnail: "",
    category: "umum",
    sub_category: "1",
    body: "",
    type: "article",
  });

  const handleChange = (e) => {
    setNewArticle({
      ...newArticle,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(newArticle);
    const thumbnail = await fileServices.uploadArticleThumbnail(
      newArticle.thumbnail,
      newArticle.title
    );
    const category = newArticle.category.toLowerCase();
    const data = { ...newArticle, thumbnail: thumbnail, category: category };
    const res = await educationServices.add(data);

    setLoading(false);

    navigate("/artikel");
  };

  return (
    <div>
      <div className="flex justify-between items-center my-6">
        <h1 className="text-2xl font-semibold">Tambah Artikel</h1>
      </div>
      <div className=" w-full lg:p-12 md:p-8 p-6 bg-white mt-5 rounded-lg shadow-lg">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <InputDefault
              name="title"
              label="Judul Artikel"
              value={newArticle.title}
              placeholder="Judul Artikel"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <InputTextArea
              name="description"
              label="Deskripsi Artikel"
              value={newArticle.description}
              placeholder="Deskripsi"
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <InputFile
              label="Thumbnail"
              onChange={(e) => {
                setNewArticle({
                  ...newArticle,
                  thumbnail: e,
                });
              }}
            />
          </div>

          <div className="mb-6">
            <InputSelect
              label="Kategori"
              data={["umum", "age", "imt", "blood_pressure"]}
              dataLabel={["Umum", "Usia", "IMT", "Anemia"]}
              value={newArticle.category}
              onChange={handleChange}
              name={"category"}
            />
          </div>

          {newArticle.category.toLocaleLowerCase() == "age" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Usia"
                data={[1, 2, 3]}
                dataLabel={["10 - 13 tahun", "14 - 17 tahun", "18 - 24 tahun"]}
                value={newArticle.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : newArticle.category.toLocaleLowerCase() == "imt" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori IMT"
                data={[1, 2, 3, 4]}
                dataLabel={[
                  "Berat badan kurang (Underweight)",
                  "Berat badan ideal (Normal)",
                  "Berat badan berlebih (Overweight)",
                  "Obesitas",
                ]}
                value={newArticle.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : newArticle.category.toLocaleLowerCase() == "blood_pressure" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Tekanan Darah"
                data={[1, 2]}
                dataLabel={["Normal", "Anemia"]}
                value={newArticle.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : null}

          <div className="mb-4">
            <div className="mb-3">
              <label className="f-p1-m text-neutral-1000">Isi Artikel</label>
            </div>
            <ReactQuill
              theme="snow"
              value={newArticle.body}
              onChange={(e) => {
                setNewArticle({ ...newArticle, body: e });
              }}
            />
          </div>

          <div className="flex gap-2 mt-6">
            <div>
              <ButtonPrimary text="Tambah" type="submit" callback={() => {}} />
            </div>
            <div>
              <ButtonSecondary
                text="Batal"
                callback={() => {
                  navigate("/artikel");
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
