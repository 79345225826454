import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ArticleDetailPage } from "src/pages/detail-edukasi/article";
import { VideoDetailPage } from "src/pages/detail-edukasi/video";
import { GamePuzzlePage } from "src/pages/game-puzzle";
import { GameQuizPage } from "src/pages/game-quiz";
import { LandingPage } from "src/pages/landing";
import { ScreeningPage } from "src/pages/screening";
import { UserDashboardPage } from "src/pages/user-dashboard";
import { UserProfilePage } from "src/pages/user-profile";
import { UserChangePasswordPage } from "src/pages/user-profile/change-password";
import { UserEditProfilePage } from "src/pages/user-profile/edit-profile";

export default function UserRouterPage() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UserDashboardPage subMenuPath={1} />} exact />
        <Route path="/artikel/:id" element={<ArticleDetailPage />} exact />
        <Route path="/video/:id" element={<VideoDetailPage />} exact />
        <Route path="/profile" element={<UserProfilePage />} exact />
        <Route path="/edit-profile" element={<UserEditProfilePage />} exact />
        <Route path="/screening" element={<ScreeningPage />} exact />

        <Route path="/game/quiz" element={<GameQuizPage />} exact />
        <Route path="/game/puzzle" element={<GamePuzzlePage />} exact />

        <Route
          path="/tentang-kami"
          element={<LandingPage subMenuPath="#tentang-kami" />}
          exact
        />
        <Route
          path="/ganti-password"
          element={<UserChangePasswordPage />}
          exact
        />

        <Route
          path="/rekomendasi"
          element={<UserDashboardPage subMenuPath={1} />}
          exact
        />
        <Route
          path="/edukasi"
          element={<UserDashboardPage subMenuPath={2} />}
          exact
        />
        <Route
          path="/game"
          element={<UserDashboardPage subMenuPath={3} />}
          exact
        />

        <Route path="*" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
}
