import { ButtonSecondary, ButtonSecondaryIcon } from "src/components/button";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { AuthServices } from "src/services/AuthServices";

export function AdminProfilePage() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const authServices = new AuthServices();

  return (
    <div>
      <div className="container w-full lg:pt-4 pt-4 mx-auto mb-8 min-h-[600px]">
        <div className="flex gap-6 w-full mx-auto">
          <div className="">
            <div className="flex justify-between items-center mb-4">
              <h3 className="f-h3">Profil</h3>
            </div>
            <div className="flex items-center gap-4 mb-4">
              <div className="rounded-circle">
                <img src={user.photo} className="rounded-full w-20 h-20" />
              </div>
              <div className="">
                <h4 className="f-h4">{user.name}</h4>
                <p className="f-p1-r">{user.email}</p>
              </div>
            </div>
            <div className="mb-3">
              <p className="f-p1-sb">Email</p>
              <p className="f-p1-r">{user.email}</p>
            </div>
            <div className="mb-3">
              <p className="f-p1-sb">Alamat</p>
              <p className="f-p1-r">{user.address}</p>
            </div>
            <div className="mb-3 mt-6 flex gap-2">
              <ButtonSecondary
                text="Log Out"
                callback={async () => {
                  try {
                    const res = await authServices.logout();
                    setUser(null);
                    navigate("/masuk");
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
              <ButtonSecondary
                text="Ubah Password"
                callback={async () => {
                  navigate("/ganti-password");
                }}
              />
              <ButtonSecondary
                text="Edit Profile"
                callback={async () => {
                  navigate("/edit-profile");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
