export function InputDefault({ name, label, placeholder, value, onChange }) {
  return (
    <div className="">
      <label className="f-p1-m text-neutral-1000">{label}</label>
      <input
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
      />
    </div>
  );
}

export function InputTextArea({ name, label, placeholder, value, onChange }) {
  return (
    <div className="">
      <label className="f-p1-m text-neutral-1000">{label}</label>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        rows={4}
        onChange={onChange}
        className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
      />
    </div>
  );
}

export function InputSelect({ name, label, dataLabel, data, value, onChange }) {
  return (
    <div className="">
      <label className="f-p1-m text-neutral-1000">{label}</label>
      <select
        name={name}
        onChange={onChange}
        value={value}
        className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-3 mt-2 focus:outline-none focus:border-primary-main"
      >
        {data.map((item, index) => {
          return (
            <option key={index} value={item}>
              {dataLabel[index]}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export function InputFile({ label, value, onChange }) {
  return (
    <div className="">
      <label className="f-p1-m text-neutral-1000">{label}</label>
      <input
        onChange={(e) => {
          onChange(e.target.files[0]);
        }}
        type="file"
        value={value}
        className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
      />
    </div>
  );
}
