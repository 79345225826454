import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserServices } from "src/services/UserServices";
import { LoadingContext } from "src/context/LoadingContext";
import { useQuery } from "react-query";
import { Card, Typography } from "@material-tailwind/react";
import { Loader } from "src/components/Loader";

const TABLE_HEAD = [
  "No",
  "Nama",
  "Email",
  "Gender",
  "Usia",
  "IMT",
  "Anemia",
  "Quiz (%)",
];

export function AdminScreeningPage() {
  const navigate = useNavigate();
  const userServices = new UserServices();

  const { loading, setLoading } = useContext(LoadingContext);

  const { isLoading, error, data } = useQuery(
    "screenings",
    async () => await userServices.getAll()
  );

  if (isLoading || loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center my-6">
        <h1 className="text-2xl font-semibold">Data Skrining</h1>
      </div>
      <Card className="overflow-scroll h-full w-full">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(
              ({ _id, name, email, gender, screening, quiz }, index) => {
                const isLast = index === data.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={_id}>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {index + 1}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {email}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {gender}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {screening.status_screening <= 2
                          ? "-"
                          : screening.age == 1
                          ? "Remaja Awal (10-13 tahun)"
                          : screening.age == 2
                          ? "Remaja Tengah (14-17 tahun)"
                          : screening.age == 3
                          ? "Remaja Akhir (18-24 tahun)"
                          : "-"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {screening.status_screening <= 2
                          ? "-"
                          : screening.imt < 18.5
                          ? "Underweight (<18)"
                          : screening.imt >= 18.5 && screening.imt < 25
                          ? "Normal (18.5-24.9)"
                          : screening.imt >= 25.0 && screening.imt < 30
                          ? "Overweight (25-29.9)"
                          : screening.imt >= 30
                          ? "Obesitas (>=30)"
                          : "-"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {screening.status_screening <= 2
                          ? "-"
                          : screening.blood_pressure >= 5
                          ? "Berpotensi Anemia"
                          : "Normal"}
                      </Typography>
                    </td>{" "}
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {quiz.true + quiz.false === 0
                          ? "-"
                          : (
                              (quiz.true / (quiz.false + quiz.true)) *
                              100
                            ).toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </Card>
    </div>
  );
}
