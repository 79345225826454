import { NavQuestion } from "../component/nav";
import { useEffect, useState } from "react";
import iconO from "src/assets/Iconography/Outline/O.svg";
import iconX from "src/assets/Iconography/Outline/X.svg";
import { PopUpAnswer } from "../component/pop-up-answer";

export function QuestionChoice({
  value,
  show,
  handlePrev,
  handleNext,
  index,
  callback,
  selected,
}) {
  const [answer, setAnswer] = useState(null);

  return (
    <div className={`flex-col w-full ${show ? "flex" : "hidden"}`}>
      {answer === null ? null : (
        <div className="flex justify-center items-center fixed left-0 top-0 z-10 w-full h-full bg-black bg-opacity-20">
          <PopUpAnswer
            value={value}
            answer={answer}
            callback={() => {
              // setAnswer(null);
              handleNext();
            }}
          />
        </div>
      )}
      <div className="mb-10">
        <NavQuestion
          handlePrev={() => handlePrev()}
          value={((index + 1) / 10) * 100 + "%"}
        />
      </div>
      <h3 className="f-h3 mb-4">{`Benar atau Salah: Pernyataan ${
        index + 1
      }`}</h3>
      <p className="f-p1-r mb-4">{value.question}</p>
      <div className="flex text-center w-full mb-5 gap-4">
        <button
          onClick={() => {
            setAnswer(1);
            callback(1);
          }}
          className="w-full lg:w-1/2 h-[90px] rounded-[8px] bg-success-border p-5 flex justify-center items-center"
        >
          <img src={iconO} />
        </button>
        <button
          onClick={() => {
            setAnswer(0);
            callback(0);
          }}
          className="w-full lg:w-1/2 h-[90px] rounded-[8px] bg-danger-border p-5 flex justify-center items-center"
        >
          <img src={iconX} />
        </button>
      </div>
    </div>
  );
}
