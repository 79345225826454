import { EducationServices } from "src/services/EducationServices";
import { CardItem } from "../component/card-item";
import { Loader } from "src/components/Loader";
import { useQuery } from "react-query";
import { useContext, useEffect } from "react";
import { UserContext } from "src/context/UserContext";

export function UntukmuSection() {
  const educationServices = new EducationServices();

  const { user, setUser } = useContext(UserContext);

  const educations = useQuery(
    "educations",
    async () =>
      await educationServices.getForMe({
        code: `${user?.screening.age}-${user?.screening.imt}-${user?.screening.blood_pressure}`,
      })
  );

  if (educations.isLoading) {
    return <Loader />;
  }

  if (educations.isError) {
    return <p>Something went wrong</p>;
  }

  return (
    <div className="w-full">
      <p className="f-p1-r mt-5">
        Artikel dan video pilihan yang disesuaikan dengan kondisimu
      </p>

      <div className="mt-2">
        {educations.data.map((item, index) => {
          return <CardItem key={index} item={item} />;
        })}
      </div>
    </div>
  );
}
