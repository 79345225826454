export function ButtonPrimary(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="bg-primaryMain hover:bg-primaryHover focus:bg-primaryFocus text-neutral-100 f-p1-m rounded-full py-3 px-5"
    >
      {props.text}
    </button>
  );
}

export function ButtonPrimaryExpand(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="bg-primaryMain hover:bg-primaryHover focus:bg-primaryFocus text-neutral-100 f-p1-m rounded-full py-3 px-5 w-full"
    >
      {props.text}
    </button>
  );
}

export function ButtonSecondary(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="bg-primary-border hover:bg-primaryHover focus:bg-primaryFocus text-primary-hover hover:text-neutral-100 f-p1-m rounded-full py-3 px-5"
    >
      {props.text}
    </button>
  );
}

export function ButtonSecondaryIcon(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="bg-primary-border hover:bg-primaryHover focus:bg-primaryFocus text-primary-hover hover:text-neutral-100 f-p1-m rounded-full py-3 px-5 flex items-center gap-2"
    >
      <img src={props.image} />
      {props.text}
    </button>
  );
}

export function ButtonSecondaryExpand(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="bg-primary-border hover:bg-primaryHover focus:bg-primaryFocus text-primary-hover hover:text-neutral-100 f-p1-m rounded-full py-3 px-5 w-full"
    >
      {props.text}
    </button>
  );
}

export function ButtonWhite(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="hover:bg-primaryMain focus:bg-primaryFocus focus:text-neutral-100 bg-neutral-100 text-neutral-1000 f-p1-m rounded-full py-3 px-5"
    >
      {props.text}
    </button>
  );
}

export function ButtonNeutral(props) {
  return (
    <button
      type={props.type ?? "button"}
      onClick={() => {
        props.callback();
      }}
      className="hover:bg-primaryMain focus:bg-primaryMain focus:text-neutral-100 hover:text-neutral-100 bg-neutral-200 text-neutral-1000 f-p1-m rounded-full py-3 px-5 w-full"
    >
      {props.text}
    </button>
  );
}
