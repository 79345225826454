import img1 from "src/assets/Screening Result/potensi-anemia/1.png";
import img2 from "src/assets/Screening Result/potensi-anemia/2.png";

export const dataScreeningResultPotensiAnemia = [
  {
    title: "Hasil skriningmu menyatakan",
    label: "Kamu berpotensi anemia",
    text: "Gawat, kamu punya resiko besar terkena Anemia.",
    image: img1,
    value: [
      {
        label: "Berpotensi Anemia",
        value: [
          {
            type: "text",
            value: "Kamu kemungkinan besar BERESIKO ANEMIA.",
          },
          {
            type: "text",
            value:
              "Cobalah melakukan pemeriksaan lebih lanjut, misalnya pemeriksaan kadar Hb di laboratorium atau apotek yang menyediakan jasa periksa Hb.",
          },
          {
            type: "text",
            value:
              "Jangan lupa untuk menjalani pola hidup sehat, konsumsi daging dan sayuran hijau, dan jangan mengkonsumsi teh setelah makan.",
          },
          {
            type: "text",
            value:
              "Kunjungi fitur edukasi untuk mendapatkan tips dan trick pencegahan anemia pada remaja.",
          },
        ],
      },
    ],
  },
  {
    title: "Hasil skriningmu menyatakan",
    label: "Kamu normal",
    text: "Selamat, kamu tidak berpotensi anemia.",
    image: img2,
    value: [
      {
        label: "Normal",
        value: [
          {
            type: "text",
            value: "Kamu kemungkinan besar NORMAL (Tidak berpotensi anemia).",
          },
          {
            type: "text",
            value:
              "Terus jalani pola hidup sehat, konsumsi makanan bergizi, dan tidak mengkonsumsi teh setelah makan.",
          },
          {
            type: "text",
            value:
              "Kunjungi fitur edukasi untuk mendapatkan tips dan trick pola hidup sehat remaja.",
          },
        ],
      },
    ],
  },
];
