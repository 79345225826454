import imageMore from "src/assets/Iconography/Solid/expand_more.svg";
import imageLess from "src/assets/Iconography/Solid/expand_less.svg";
import { useState } from "react";

export function CardExpand(props) {
  const [expand, setExpand] = useState(true);

  return (
    <div className="bg-neutral-200 rounded-lg">
      <div
        className={`border-b-2 py-3 px-5 grid grid-cols-12 ${
          expand ? "border-neutral-400" : "border-transparent"
        }`}
      >
        <div className="col-span-11">
          <p className="f-p2-sb">{props.data.label}</p>
        </div>
        <div className="col-span-1 flex justify-end">
          <button
            onClick={() => {
              setExpand(!expand);
            }}
          >
            <img src={expand ? imageLess : imageMore} />
          </button>
        </div>
      </div>
      <div className={`px-5 py-3 flex-col gap-2 ${expand ? "flex" : "hidden"}`}>
        {props.data.value.map((item, index) => {
          return item.type == "text" ? (
            <Text key={index} value={item.value} />
          ) : item.type == "image" ? (
            <Image key={index} value={item.value} />
          ) : item.type == "text-bold" ? (
            <TextBold key={index} value={item.value} />
          ) : item.type == "text-center" ? (
            <TextCenter key={index} value={item.value} />
          ) : item.type == "list" ? (
            <List key={index} value={item.value} />
          ) : item.type == "list-image" ? (
            <ListImage key={index} value={item.value} />
          ) : (
            "other"
          );
        })}
      </div>
    </div>
  );
}

function Text({ value }) {
  return <p className="f-p2-r my-1">{value}</p>;
}

function TextBold({ value }) {
  return <p className="f-p2-sb my-1">{value}</p>;
}

function TextCenter({ value }) {
  return <p className="f-p2-r text-center my-1">{value}</p>;
}

function Image({ value }) {
  return (
    <div className="flex justify-center my-3">
      <img src={value} className="w-36" />
    </div>
  );
}

function List({ value }) {
  return (
    <ul className="list-disc px-4 my-3">
      {value.map((item, index) => {
        return (
          <li>
            {item.type == "text" ? (
              <Text key={index} value={item.value} />
            ) : item.type == "image" ? (
              <Image key={index} value={item.value} />
            ) : item.type == "text-bold" ? (
              <TextBold key={index} value={item.value} />
            ) : item.type == "text-center" ? (
              <TextCenter key={index} value={item.value} />
            ) : item.type == "list" ? (
              <List key={index} value={item.value} />
            ) : (
              "other"
            )}
          </li>
        );
      })}
    </ul>
  );
}

function ListImage({ value }) {
  return (
    <ul className="list-disc px-4 my-3">
      {value.map((item, index) => {
        return (
          <li>
            {item.type == "text" ? (
              <Text key={index} value={item.value} />
            ) : item.type == "image" ? (
              <Image key={index} value={item.value} />
            ) : item.type == "text-bold" ? (
              <TextBold key={index} value={item.value} />
            ) : item.type == "text-center" ? (
              <TextCenter key={index} value={item.value} />
            ) : item.type == "list" ? (
              <List key={index} value={item.value} />
            ) : item.type == "vertical-list" ? (
              <VerticalList key={index} value={item.value} />
            ) : (
              "other"
            )}
          </li>
        );
      })}
    </ul>
  );
}

function VerticalList({ value }) {
  return value.map((item, index) => {
    return item.type == "text" ? (
      <p>{item.value}</p>
    ) : item.type == "image" ? (
      <Image key={index} value={item.value} />
    ) : item.type == "text-bold" ? (
      <TextBold key={index} value={item.value} />
    ) : item.type == "text-center" ? (
      <TextCenter key={index} value={item.value} />
    ) : item.type == "list" ? (
      <List key={index} value={item.value} />
    ) : (
      "other"
    );
  });
}
