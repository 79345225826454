import { useQuery } from "react-query";
import { Loader } from "src/components/Loader";
import { VisitorServices } from "src/services/VisitorServices";

export function AdminDashboardPage() {
  const visitorServices = new VisitorServices();

  const { isLoading, error, data } = useQuery(
    "getVisitor",
    async () => await visitorServices.getAll()
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <h3 className="f-h3">{error}</h3>;
  }

  return (
    <div>
      <div class="max-w-full mx-1 py-1 sm:mx-auto sm:px-1 lg:px-1">
        <div class="sm:flex sm:space-x-4">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div class="bg-white p-5">
              <div class="sm:flex sm:items-start">
                <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 class="text-sm leading-6 font-medium text-gray-400">
                    Total Pengunjung
                  </h3>
                  <p class="text-3xl font-bold text-black">
                    {data?.total ?? "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div class="bg-white p-5">
              <div class="sm:flex sm:items-start">
                <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 class="text-sm leading-6 font-medium text-gray-400">
                    Pengunjung Hari Ini
                  </h3>
                  <p class="text-3xl font-bold text-black">
                    {data?.daily ?? "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
            <div class="bg-white p-5">
              <div class="sm:flex sm:items-start">
                <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
                  <h3 class="text-sm leading-6 font-medium text-gray-400">
                    Pengunjung Bulan Ini
                  </h3>
                  <p class="text-3xl font-bold text-black">
                    {data?.monthly ?? "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
