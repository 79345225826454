import { FiX } from "react-icons/fi";

export function Error({ message, handleClose }) {
  return (
    <div className="flex justify-center items-center fixed left-0 top-0 z-10 w-full h-full bg-black bg-opacity-20">
      <div className="w-11/12 lg:w-[480px] h-fit relative overflow-y-auto bg-white rounded-[8px] p-8 flex-col">
        <button onClick={handleClose} className="absolute right-3 top-3">
          <FiX size={24} />
        </button>
        <h1 className="f-h3 text-center">{message.header}</h1>
        <p className="f-p2-r text-center mt-4">{message.body}</p>
      </div>
    </div>
  );
}
