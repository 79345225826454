import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCC7-F5UIeFgwSQCav9xfC7aK5-EnzaPnQ",
  authDomain: "reaksi-skrining.firebaseapp.com",
  projectId: "reaksi-skrining",
  storageBucket: "reaksi-skrining.appspot.com",
  messagingSenderId: "692793722842",
  appId: "1:692793722842:web:7134c446af6732e1e522e3"
};

const app = initializeApp(firebaseConfig);
export default app;
