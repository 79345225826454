import { Footer } from "src/components/footer";
import { NavbarDashboard } from "src/components/navbar";
import React, { useContext } from "react";
import { CardRecommendation } from "./component/card-recommendation";
import { EducationServices } from "src/services/EducationServices";
import { useQuery } from "react-query";
import { Loader } from "src/components/Loader";
import { useParams } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
import { UserContext } from "src/context/UserContext";

export function ArticleDetailPage() {
  const { id } = useParams();
  const educationServices = new EducationServices();
  const { user, setUser } = useContext(UserContext);

  const educationForYou = useQuery(
    "educationsForYou",
    async () =>
      await educationServices.getForMe({
        code: `${user?.screening.age}-${user?.screening.imt}-${user?.screening.blood_pressure}`,
      })
  );

  const article = useQuery(
    "article",
    async () => await educationServices.getById(id)
  );

  return (
    <div className="bg-neutral-100">
      <NavbarDashboard />
      <div className="container w-11/12 lg:pt-28 pt-24 mx-auto mb-8">
        <div className="grid grid-cols-12 gap-5 w-full">
          {article.isLoading ? (
            <Loader />
          ) : article.isError ? (
            <p>Something went wrong</p>
          ) : (
            <div className="lg:col-span-8 col-span-12 bg-neutral-100">
              <h2 className="f-h2 mb-5">{article.data.title}</h2>

              <div className="w-full">
                <img src={article.data.thumbnail} />
              </div>

              <div className="mt-4">
                {/* <ReactMarkdown>{article.data.body}</ReactMarkdown> */}
                {/* <ReactQuill
                  theme={"bubble"}
                  value={article?.data.body ?? ""}
                  readOnly={true}
                /> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: article?.data.body ?? "",
                  }}
                />
              </div>
            </div>
          )}

          <div className="lg:col-span-4 col-span-12 bg-neutral-100">
            <div>
              <h3 className="f-h4">
                Konten Yang Masih Menjadi Rekomendasi Untukmu
              </h3>
            </div>
            {educationForYou.isLoading ? (
              <Loader />
            ) : educationForYou.isError ? (
              <p>Something went wrong</p>
            ) : (
              <div>
                {educationForYou.data.map((item, index) => {
                  return <CardRecommendation key={index} item={item} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
