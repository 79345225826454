import { useContext, useEffect, useState } from "react";
import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { QuestionChoice } from "./section/question";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/context/UserContext";

import imageLoading from "src/assets/Illustration/loading.svg";
import iconO from "src/assets/Iconography/Outline/O.svg";
import iconX from "src/assets/Iconography/Outline/X.svg";
import iconSmile from "src/assets/Iconography/Solid/smile.svg";
import iconUnsmile from "src/assets/Iconography/Solid/unsmile.svg";
import { dataTebak } from "src/data/game/tebak";
import { UserServices } from "src/services/UserServices";

export function GameQuizPage() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [answers, setAnswers] = useState([]);

  const [randomQuestions, setRandomQuestions] = useState([]);

  useEffect(() => {
    shuffleArray();
  }, []);

  const shuffleArray = () => {
    const shuffledIndices = [];
    const copyArray = [...dataTebak];

    while (copyArray.length > 5) {
      const randomIndex = Math.floor(Math.random() * copyArray.length);
      const removedItem = copyArray.splice(randomIndex, 1)[0];
      shuffledIndices.push(removedItem);
    }

    setRandomQuestions(shuffledIndices);
  };

  return (
    <div className="w-full bg-white">
      <div className="container lg:pt-24 pt-8 mx-auto mb-8 min-h-screen lg:w-[480px] w-11/12 flex flex-col items-center">
        {currentQuestion === 0 ? (
          <div className="flex flex-col w-11/12 lg:w-full pt-20">
            <h1 className="f-h1 mb-8">Petunjuk Permainan: Yes/No Quiz</h1>
            <ol className="text-left list-decimal mb-4">
              <li className="f-p1-r mb-2">Kamu akan diberikan 10 pernyataan</li>
              <li className="f-p1-r mb-2">
                Tugasmu adalah menentukan bahwa pernyataan tersebut benar atau
                salah
              </li>
              <li className="f-p1-r mb-2">
                Simbol{" "}
                <span>
                  <img src={iconO} className="w-4" />
                </span>{" "}
                menandakan <b>Benar</b>
              </li>
              <li className="f-p1-r mb-2">
                Simbol <img src={iconX} className="w-4" />
                menandakan <b>Salah</b>
              </li>
            </ol>

            <div className="mb-2">
              <h3 className="f-p1-r text-center">Siap bermain?</h3>
            </div>

            <div className="mb-2 w-full">
              <ButtonPrimaryExpand
                text="Siap Mulai"
                callback={() => {
                  setCurrentQuestion(1);
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="w-11/12 lg:w-full">
          {randomQuestions?.map((item, index) => {
            return (
              <QuestionChoice
                key={index}
                value={item}
                show={index == currentQuestion - 1}
                handlePrev={() => {
                  setCurrentQuestion(currentQuestion - 1);
                }}
                handleNext={() => {
                  setCurrentQuestion(currentQuestion + 1);
                }}
                index={index}
                callback={(value) => {
                  const newAnswers = [...answers];
                  newAnswers[index] = value;
                  setAnswers(newAnswers);
                }}
                selected={answers[index]}
              />
            );
          })}

          {currentQuestion === randomQuestions?.length + 1 ? (
            <LoadingResult
              answers={answers}
              questions={randomQuestions}
              callback={() => {
                navigate("/game");
              }}
              user={user}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function LoadingResult({ answers, questions, callback, user }) {
  const userServices = new UserServices();
  const [result, setResult] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      var right = 0;
      for (var i = 0; i < answers.length; i++) {
        if (answers[i] === questions[i].key) {
          right++;
        }
      }

      userServices.updateQuiz({
        _id: user._id,
        quizTrue: right,
        quizFalse: questions.length - right,
      });

      setResult(right);
    }, 300);
  }, []);

  if (result == null) {
    return (
      <div className="text-center flex flex-col items-center">
        <h3 className="f-h3 mb-8">Menganalisa Skormu</h3>
        <div className="mb-8">
          <img src={imageLoading} />
        </div>
        <p className="f-p1-r">
          Tenang, ini hanya memakan waktu <br /> beberapa detik
        </p>
      </div>
    );
  } else {
    return (
      <div className="text-center flex flex-col items-center">
        <div className="mb-8">
          <img src={result >= 10 ? iconSmile : iconUnsmile} />
        </div>
        {result >= 10 ? (
          <div className="mb-4">
            <h3 className="f-h3 mb-8">
              CONGRATS <br /> YOU ARE WIN
            </h3>
            <p className="f-p1-r">Kamu berhasil menebak semua secara tepat</p>
          </div>
        ) : (
          <div className="mb-4">
            <h3 className="f-h3 mb-8">
              Never Give Up! <br /> Kamu Hanya Berhasil <br /> Menebak Secara
              Tepat <br /> {result}/10
            </h3>
            <p className="f-p1-r">
              Ayo tingkatkan pengetahuanmu untuk mendapatkan skor yang lebih
              tinggi.
            </p>
          </div>
        )}

        <div className="mb-2 w-full">
          <ButtonPrimaryExpand
            text="Main Lagi"
            callback={() => {
              callback();
            }}
          />
        </div>
        <div className="mb-2 w-full">
          <ButtonSecondaryExpand
            text="Kembali ke Beranda"
            callback={() => {
              callback();
            }}
          />
        </div>
      </div>
    );
  }
}
