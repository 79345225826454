import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
  ButtonSecondaryIcon,
} from "src/components/button";
import { Footer } from "src/components/footer";
import { NavbarDashboard } from "src/components/navbar";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Loading } from "src/components/Loader";
import { LoadingContext } from "src/context/LoadingContext";
import { AuthServices } from "src/services/AuthServices";
import imageVisibility from "src/assets/Iconography/Solid/visibility.svg";

export function UserChangePasswordPage() {
  const navigate = useNavigate();
  const authServices = new AuthServices();

  const { loading, setLoading } = useContext(LoadingContext);

  const [editPassword, setEditPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmation: "",
  });
  const [errorInput, setErrorInput] = useState({});
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmationPasswordVisible, setIsConfirmationPasswordVisible] =
    useState(false);

  const handleChange = (e) => {
    setEditPassword({ ...editPassword, [e.target.name]: e.target.value });

    const temp = { ...editPassword, [e.target.name]: e.target.value };
    validationStage(temp);
  };

  const validationStage = (data) => {
    setErrorInput(null);

    if (data.oldPassword == "") {
      setErrorInput({ oldPassword: "Password tidak boleh kosong" });
      return false;
    } else if (data.oldPassword.length < 8) {
      setErrorInput({ oldPassword: "Password minimal 8 karakter" });
      return false;
    }

    if (data.newPassword == "") {
      setErrorInput({ newPassword: "Password baru tidak boleh kosong" });
      return false;
    } else if (data.newPassword.length < 8) {
      setErrorInput({ newPassword: "Password baru minimal 8 karakter" });
      return false;
    }

    if (data.confirmation == "") {
      setErrorInput({ confirmation: "Konfirmasi password tidak boleh kosong" });
      return false;
    }

    if (data.newPassword != data.confirmation) {
      setErrorInput({ confirmation: "Konfirmasi password baru tidak sama" });
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await authServices.changePassword(
      editPassword.oldPassword,
      editPassword.newPassword
    );

    setLoading(false);

    if (res == true) navigate("/profile");
    else alert(res.message);
  };

  return (
    <div className="">
      <Loading loading={loading} />
      <NavbarDashboard />
      <div className="container lg:w-10/12 w-11/12 lg:pt-28 pt-24 mx-auto mb-8 min-h-[600px]">
        <div className="lg:w-[800px] mx-auto">
          <div className="flex mb-4">
            <h3 className="f-h3">Ganti Password</h3>
          </div>

          <form className="lg:col-span-6 col-span-9" onSubmit={handleSubmit}>
            <div className="mt-4 relative">
              <label className="f-p1-m text-neutral-1000">
                Password Sekarang
              </label>
              <button
                type="button"
                placeholder="Masukkan password sekarang"
                onClick={() => {
                  setIsOldPasswordVisible(!isOldPasswordVisible);
                }}
                className="absolute top-[40px] right-3"
              >
                <img src={imageVisibility} className="w-6" />
              </button>
              <input
                name="oldPassword"
                onChange={handleChange}
                value={editPassword.oldPassword}
                placeholder="******"
                type={isOldPasswordVisible ? "text" : "password"}
                className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
              />
              <p className="f-p2-r text-danger-main">
                {errorInput?.oldPassword ?? ""}
              </p>
            </div>

            <div className="mt-4 relative">
              <label className="f-p1-m text-neutral-1000">Password Baru</label>
              <button
                type="button"
                placeholder="Masukkan password baru"
                onClick={() => {
                  setIsNewPasswordVisible(!isNewPasswordVisible);
                }}
                className="absolute top-[40px] right-3"
              >
                <img src={imageVisibility} className="w-6" />
              </button>
              <input
                name="newPassword"
                onChange={handleChange}
                value={editPassword.newPassword}
                placeholder="******"
                type={isNewPasswordVisible ? "text" : "password"}
                className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
              />
              <p className="f-p2-r text-danger-main">
                {errorInput?.newPassword ?? ""}
              </p>
            </div>

            <div className="mt-4 mb-4 relative">
              <label className="f-p1-m text-neutral-1000">
                Konfirmasi Password Baru
              </label>
              <button
                type="button"
                placeholder="Masukkan konfirmasi password baru"
                onClick={() => {
                  setIsConfirmationPasswordVisible(
                    !isConfirmationPasswordVisible
                  );
                }}
                className="absolute top-[40px] right-3"
              >
                <img src={imageVisibility} className="w-6" />
              </button>
              <input
                name="confirmation"
                onChange={handleChange}
                value={editPassword.confirmation}
                placeholder="******"
                type={isConfirmationPasswordVisible ? "text" : "password"}
                className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
              />
              <p className="f-p2-r text-danger-main">
                {errorInput?.confirmation ?? ""}
              </p>
            </div>

            {errorInput == null ? (
              <div className="mb-4">
                <ButtonPrimaryExpand
                  text="Simpan"
                  callback={() => {}}
                  type="submit"
                />
              </div>
            ) : (
              <div className="mb-4">
                <ButtonSecondaryExpand
                  text="Simpan"
                  callback={() => {}}
                  type="button"
                />
              </div>
            )}

            <div>
              <ButtonSecondaryExpand
                text="Batal"
                callback={() => {
                  navigate("/profile");
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
