import { useNavigate, useParams } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary } from "src/components/button";
import {
  InputDefault,
  InputFile,
  InputSelect,
  InputTextArea,
} from "src/components/input";

import { useContext, useState } from "react";
import FileServices from "src/services/FileServices";
import { EducationServices } from "src/services/EducationServices";
import { LoadingContext } from "src/context/LoadingContext";
import { useQuery } from "react-query";
import { Loader, Loading } from "src/components/Loader";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function AdminEditArtikelPage() {
  const navigate = useNavigate();
  const fileServices = new FileServices();
  const educationServices = new EducationServices();

  const { id } = useParams();
  const { loading, setLoading } = useContext(LoadingContext);

  const [article, setArticle] = useState(null);

  const { isLoading, error, data } = useQuery("article", async () => {
    const res = await educationServices.getById(id);
    setArticle(res);
    return res;
  });

  const handleChange = (e) => {
    setArticle({
      ...article,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(newArticle);

    if (article.thumbnail != data.thumbnail) {
      const thumbnail = await fileServices.uploadArticleThumbnail(
        article.thumbnail,
        article.title
      );

      const category = article.category.toLowerCase();
      const temp = { ...article, thumbnail: thumbnail, category: category };
      const { _id, ...dataArticle } = temp;
      const res = await educationServices.update(_id, temp);
    } else {
      const category = article.category.toLowerCase();
      const temp = { ...article, category: category };
      const { _id, ...dataArticle } = temp;
      const res = await educationServices.update(_id, dataArticle);
    }

    setLoading(false);

    navigate("/artikel");
  };

  if (isLoading || loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center my-6">
        <h1 className="text-2xl font-semibold">Ubah Artikel</h1>
      </div>
      <div className=" w-full lg:p-12 md:p-8 p-6 bg-white mt-5 rounded-lg shadow-lg">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <InputDefault
              name="title"
              label="Judul Artikel"
              value={article?.title}
              placeholder="Judul Artikel"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <InputTextArea
              name="description"
              label="Deskripsi Artikel"
              value={article?.description}
              placeholder="Deskripsi"
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <InputFile
              label="Thumbnail"
              onChange={(e) => {
                setArticle({
                  ...article,
                  thumbnail: e,
                });
              }}
            />
          </div>

          <div className="mb-6">
            <InputSelect
              label="Kategori"
              data={["umum", "age", "imt", "blood_pressure"]}
              dataLabel={["Umum", "Usia", "IMT", "Anemia"]}
              value={article?.category}
              onChange={handleChange}
              name={"category"}
            />
          </div>

          {article?.category.toLocaleLowerCase() == "age" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Usia"
                data={[1, 2, 3]}
                dataLabel={["10 - 13 tahun", "14 - 17 tahun", "18 - 24 tahun"]}
                value={article?.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : article?.category.toLocaleLowerCase() == "imt" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori IMT"
                data={[1, 2, 3, 4]}
                dataLabel={[
                  "Berat badan kurang (Underweight)",
                  "Berat badan ideal (Normal)",
                  "Berat badan berlebih (Overweight)",
                  "Obesitas",
                ]}
                value={article?.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : article?.category.toLocaleLowerCase() == "blood_pressure" ? (
            <div className="mb-6">
              <InputSelect
                label="Sub Kategori Tekanan Darah"
                data={[1, 2]}
                dataLabel={["Normal", "Anemia"]}
                value={article?.sub_category}
                onChange={handleChange}
                name={"sub_category"}
              />
            </div>
          ) : null}

          <div className="mb-4">
            <div className="mb-3">
              <label className="f-p1-m text-neutral-1000">Isi Artikel</label>
            </div>

            <ReactQuill
              theme="snow"
              value={article?.body}
              onChange={(e) => {
                setArticle({ ...article, body: e });
              }}
            />
          </div>

          {/* <div className="mb-4">
            <div
              dangerouslySetInnerHTML={{
                __html: new MarkdownIt().render(article?.body ?? ""),
              }}
            />
          </div> */}

          <div className="flex gap-2 mt-6">
            <div>
              <ButtonPrimary text="Ubah" type="submit" callback={() => {}} />
            </div>
            <div>
              <ButtonSecondary
                text="Batal"
                callback={() => {
                  navigate("/artikel");
                }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
