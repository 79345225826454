export function BadgePrimary(props) {
  return (
    <button
      onClick={() => {
        props.callback();
      }}
      className="bg-primaryMain hover:bg-primaryHover focus:bg-primaryFocus text-neutral-100 f-p2-r rounded-full py-1 px-3"
    >
      {props.text}
    </button>
  );
}

export function BadgeSecondary(props) {
  return (
    <button
      onClick={() => {
        props.callback();
      }}
      className="bg-primary-border hover:bg-primaryHover focus:bg-primaryFocus text-primary-hover hover:text-neutral-100 f-p2-r rounded-full py-1 px-3"
    >
      {props.text}
    </button>
  );
}
