import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { Footer } from "src/components/footer";
import { UserContext } from "src/context/UserContext";
import { useContext, useEffect, useState } from "react";
import { InputDefault, InputSelect, InputTextArea } from "src/components/input";
import { useNavigate } from "react-router-dom";
import { Loader } from "src/components/Loader";
import { UserServices } from "src/services/UserServices";
import { LoadingContext } from "src/context/LoadingContext";
import FileServices from "src/services/FileServices";

export function AdminEditProfilePage() {
  const navigate = useNavigate();
  const userServices = new UserServices();
  const fileServices = new FileServices();

  const { user, setUser } = useContext(UserContext);
  const { loading, setLoading } = useContext(LoadingContext);

  const [editUser, setEditUser] = useState();

  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    setEditUser(user);
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };

      reader.readAsDataURL(file);
    }
    const photo = await fileServices.uploadPhotoProfile(file, user.email);
    const resUpdate = await userServices.updatePhotoProfile({
      _id: user._id,
      photo: photo,
    });
  };

  const handleChange = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await userServices.update(editUser);

    setLoading(false);

    navigate("/profile");
  };

  return (
    <div className="">
      <div className="container lg:w-10/12 w-11/12 lg:pt-10 pt-10 mx-auto mb-8 min-h-[600px]">
        <div className="grid grid-cols-9 gap-6 lg:w-[800px] mx-auto">
          <div className="lg:col-span-3 col-span-9 flex flex-col items-center">
            <div className="flex mb-4">
              <h3 className="f-h3">Edit Profil</h3>
            </div>
            <div className="rounded-circle mb-2">
              <img
                src={selectedImage ?? user.photo}
                className="rounded-full w-20 h-20"
              />
              <input
                id="photo"
                type="file"
                onChange={handleImageChange}
                className="hidden"
              />
            </div>
            <label
              htmlFor="photo"
              className="f-p1-m text-primary-main cursor-pointer"
            >
              Ganti foto profil
            </label>
          </div>
          {editUser == null ? (
            <Loader />
          ) : (
            <form className="lg:col-span-6 col-span-9" onSubmit={handleSubmit}>
              <div className="mb-4">
                <InputDefault
                  label="Nama"
                  placeholder="Nama lengkap"
                  value={editUser.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <InputSelect
                  label="Gender"
                  data={["Laki-laki", "Perempuan"]}
                  dataLabel={["Laki-laki", "Perempuan"]}
                  value={editUser.gender}
                  name="gender"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <InputDefault
                  label="Email"
                  placeholder="contoh@email.com"
                  value={editUser.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <InputTextArea
                  label="Alamat"
                  placeholder="Alamat rumah tinggal"
                  value={editUser.address}
                  name="address"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <ButtonPrimaryExpand
                  text="Simpan"
                  callback={() => {}}
                  type="submit"
                />
              </div>
              <div>
                <ButtonSecondaryExpand
                  text="Batal"
                  callback={() => {
                    navigate("/profile");
                  }}
                />
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
