import React, { useState } from "react";
import axios from "axios";
import baseUrl from "src/config/Url";

export class EducationServices {
  async add(data) {
    const res = await axios.post(`${baseUrl}/education`, data);
    return res.data;
  }

  async getById(id) {
    const res = await axios.get(`${baseUrl}/education/id?id=${id}`);
    return res.data;
  }

  async getAll() {
    const res = await axios.get(`${baseUrl}/educations`);
    return res.data;
  }

  async getForMe({ code }) {
    const queryCode = code.split("-");
    const age = queryCode[0];
    const imt =
      queryCode[1] < 18.5
        ? "1"
        : queryCode[1] >= 18.5 && queryCode[1] < 25
        ? "2"
        : queryCode[1] >= 25 && queryCode[1] < 30
        ? "3"
        : queryCode[1] > -30
        ? "4"
        : null;
    const blood_pressure = queryCode >= 5 ? "1" : "2";

    const res = await axios.get(
      `${baseUrl}/educations/for-me?code=${age}-${imt}-${blood_pressure}`
    );
    return res.data;
  }

  async getAllByType({ type }) {
    const res = await axios.get(`${baseUrl}/educations/type?type=${type}`);
    return res.data;
  }

  async getAllByCategory({ category }) {
    const res = await axios.get(
      `${baseUrl}/educations/category?category=${category}`
    );
    return res.data;
  }

  async getAllByCategoryPagination({ category, pageNumber, pageSize }) {
    const res = await axios.get(
      `${baseUrl}/educations/category/pagination?code=${category}-${pageNumber}-${pageSize}`
    );
    return res.data;
  }

  async delete(id) {
    const res = await axios.delete(`${baseUrl}/education?_id=${id}`);
    return res.data;
  }

  async update(id, data) {
    const res = await axios.put(`${baseUrl}/education`, {
      _id: id,
      data: data,
    });
    return res.data;
  }
}
