import imageClose from "src/assets/Iconography/Solid/Close Info Button.svg";
import { CardExpand } from "../component/card-expand";

export function ScreeningResult(props) {
  return (
    <div className="lg:w-[720px] lg:h-[500px] h-screen bg-neutral-100 rounded-xl shadow-s1 overflow-scroll px-6 pt-6 lg:grid flx flex-col grid-cols-12 gap-3">
      <div className="lg:col-span-4 col-span-12 h-fit">
        <div className="w-full grid grid-cols-12">
          <div className="lg:col-span-12 col-span-11">
            <p className="f-p2-r">{props.data.title}</p>
            <p className="f-p1-sb">{props.data.label}</p>
          </div>
          <div className="lg:hidden block col-span-1">
            <button
              onClick={() => {
                props.handleClose();
              }}
            >
              <img src={imageClose} />
            </button>
          </div>
        </div>
        <div className="mt-4 flex justify-center">
          <img src={props.data.image} className="w-36" />
        </div>
      </div>
      <div className="lg:col-span-8 col-span-12 lg:mt-0 mt-5">
        <div className="w-full grid grid-cols-12">
          <div className="col-span-12 lg:col-span-11">
            <p className="f-p2-m">{props.data.text}</p>
          </div>
          <div className="lg:col-span-1 lg:flex justify-end items-start hidden">
            <button
              onClick={() => {
                props.handleClose();
              }}
            >
              <img src={imageClose} />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 my-4">
          {props.data.value.map((item, index) => {
            return <CardExpand key={index} data={item} />;
          })}
        </div>
      </div>
    </div>
  );
}
