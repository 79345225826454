import image1_1 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/1.png";
import image1_2 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/2.png";
import image1_3 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/3.png";
import image1_4 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/4.png";
import image1_5 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/5.png";
import image1_6 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/6.png";
import image1_7 from "src/assets/Screening Result/tahap-remaja/perempuan/10-13/7.png";
import image2_1 from "src/assets/Screening Result/tahap-remaja/perempuan/14-17/1.png";
import image2_2 from "src/assets/Screening Result/tahap-remaja/perempuan/14-17/2.png";
import image2_3 from "src/assets/Screening Result/tahap-remaja/perempuan/14-17/3.png";
import image2_4 from "src/assets/Screening Result/tahap-remaja/perempuan/14-17/4.png";
import image2_5 from "src/assets/Screening Result/tahap-remaja/perempuan/14-17/5.png";
import image3_1 from "src/assets/Screening Result/tahap-remaja/perempuan/18-24/1.png";
import image3_2 from "src/assets/Screening Result/tahap-remaja/perempuan/18-24/2.png";
import image3_3 from "src/assets/Screening Result/tahap-remaja/perempuan/18-24/3.png";
import image3_4 from "src/assets/Screening Result/tahap-remaja/perempuan/18-24/4.png";

import img1_1 from "src/assets/Screening Result/tahap-remaja/laki/10-13/1.png";
import img1_2 from "src/assets/Screening Result/tahap-remaja/laki/10-13/2.png";
import img1_3 from "src/assets/Screening Result/tahap-remaja/laki/10-13/3.png";
import img1_4 from "src/assets/Screening Result/tahap-remaja/laki/10-13/4.png";
import img2_1 from "src/assets/Screening Result/tahap-remaja/laki/14-17/1.png";
import img2_2 from "src/assets/Screening Result/tahap-remaja/laki/14-17/2.png";
import img2_3 from "src/assets/Screening Result/tahap-remaja/laki/14-17/3.png";
import img2_4 from "src/assets/Screening Result/tahap-remaja/laki/14-17/4.png";
import img2_5 from "src/assets/Screening Result/tahap-remaja/laki/14-17/5.png";
import img2_6 from "src/assets/Screening Result/tahap-remaja/laki/14-17/6.png";
import img3_1 from "src/assets/Screening Result/tahap-remaja/laki/18-24/1.png";
import img3_2 from "src/assets/Screening Result/tahap-remaja/laki/18-24/2.png";
import img3_3 from "src/assets/Screening Result/tahap-remaja/laki/18-24/3.png";

export const dataScreeningResultTahapRemaja = [
  {
    label: "Perempuan",
    value: [
      {
        title: "Saat ini kamu berada pada tahap",
        label: "Remaja Awal 10-13 tahun",
        text: "Beberapa hal yang kamu alami di tahap ini adalah sebagai berikut:",
        image: image1_1,
        value: [
          {
            label: "Perubahan Fisik",
            value: [
              {
                type: "image",
                value: image1_2,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, Kamu mulai tumbuh lebih cepat dan mengalami tahap awal pubertas. ",
              },
              {
                type: "image",
                value: image1_3,
              },
              {
                type: "text",
                value:
                  "Kamu akan mengalami perubahan tubuh yang seringkali menjadi perhatian Kamu, seperti tumbuh rambut di ketiak dan sekitar alat kelamin, payudara mulai berkembang, dan mengalami menstruasi. Menstruasi normal terjadi di usia 12 tahun atau rata-rata 2-3 tahun setelah payudara mulai tumbuh. Anak perempuan biasanya tumbuh lebih cepat dari anak laki-laki. Bahkan, beberapa perubahan pada perempuan juga normal dialami sejak usia 8 tahun.",
              },
              {
                type: "text-bold",
                value:
                  "Hadapi perubahan fisik dengan percaya diri dan selalu menjaga kebersihan diri.",
              },
              {
                type: "image",
                value: image1_4,
              },
            ],
          },
          {
            label: "Perubahan Emosional",
            value: [
              {
                type: "text",
                value:
                  "Pada tahap ini, Kamu mulai mengalami peningkatan dalam minat seksual, sehingga timbul rasa tertarik dengan lawan jenis. Kamu juga mulai memiliki pemikiran yang konkrit.",
              },
              {
                type: "image",
                value: image1_5,
              },
              {
                type: "text",
                value:
                  "Misalnya, Kamu mulai mencari kebenaran mengenai suatu hal (bisa baik atau buruk) dari berbagai sumber.",
              },
              {
                type: "image",
                value: image1_6,
              },
              {
                type: "text",
                value:
                  "Kamu juga mulai lebih memperhatikan penampilan mulai memerlukan privasi, sehingga membuat Kamu lebih senang menyendiri dari keluarga.",
              },
              {
                type: "image",
                value: image1_7,
              },
              {
                type: "text-bold",
                value:
                  "Jalani tahap remaja awal Kamu dengan penuh tanggung jawab dan selalu membekali diri dengan ilmu pengetahuan dan ilmu agama agar Kamu tidak terjerumus pada jalan yang salah (kenakalan remaja).",
              },
              {
                type: "text",
                value:
                  "Kunjungi fitur edukasi untuk mendapatkan segudang tips dan trik pola hidup sehat remaja agar masa remajamu lebih sehat dan produktif.",
              },
            ],
          },
        ],
      },
      {
        title: "Saat ini kamu berada pada tahap",
        label: "Remaja Tengah 14-17 tahun",
        text: "Beberapa hal yang kamu alami di tahap ini adalah sebagai berikut:",
        image: image2_1,
        value: [
          {
            label: "Perubahan Fisik",
            value: [
              {
                type: "image",
                value: image2_2,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, pertumbuhan dan perkembangan Kamu masih berlanjut. Beberapa perubahan tubuh yang umum terjadi pada tahap ini adalah pinggang, panggul, dan pantat akan mulai membesar, alat reproduksi mulai berkembang, produksi keringat bertambah, dan menstruasi mulai teratur.",
              },
              {
                type: "text-bold",
                value:
                  "Hadapi perubahan fisik pada masa pertengahan remaja ini dengan penuh percaya diri dan selalu menjaga kebersihan",
              },
            ],
          },
          {
            label: "Perubahan Emosional",
            value: [
              {
                type: "image",
                value: image2_3,
              },
              {
                type: "text",
                value:
                  "Pada tahap ini, umumnya Kamu sudah dapat berpikir dengan logika meski masih sering didorong oleh perasaannya. Kamu juga mulai tertarik dengan hubungan romantis (pacaran).",
              },
              {
                type: "image",
                value: image2_4,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, Kamu juga cukup sensitif, sehingga membuat Kamu lebih sering beradu pendapat dengan orang tua. Pada masa ini, Kamu juga lebih senang menghabiskan waktu dengan teman.",
              },
              {
                type: "image",
                value: image2_5,
              },

              {
                type: "text",
                value:
                  "Pada masa ini, perkuat ilmu agama dan ilmu pengetahuan, serta pilihlah circle pertemanan yang positif agar Kamu tidak terjerumus pada jalan yang salah (kenakalan remaja). Kamu juga bisa mengisi masa pertengahan remaja ini dengan kegiatan positif, seperti mengikuti perlombaan dan bergabung dalam organisasi agar masa remaja Kamu lebih produktif dan gemilang. Kunjungi fitur edukasi untuk mendapatkan segudang tips dan trik pola hidup sehat remaja agar masa remajamu lebih sehat dan produktif.",
              },
            ],
          },
        ],
      },
      {
        title: "Saat ini kamu berada pada tahap",
        label: "Remaja Akhir 18-24 tahun",
        text: "Beberapa hal yang kamu alami di tahap ini adalah sebagai berikut:",
        image: image3_1,
        value: [
          {
            label: "Perubahan Fisik",
            value: [
              {
                type: "image",
                value: image3_2,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, fisik Kamu telah berkembang sepenuhnya. Menstruasi Kamu normalnya juga sudah sangat teratur setiap bulannya.",
              },
              {
                type: "image",
                value: image3_3,
              },
              {
                type: "text",
                value:
                  "Jaga selalu kesehatan Kamu dengan pola hidup bersih dan sehat agar Kamu bisa memasuki masa dewasa dengan sehat dan produktif.",
              },
            ],
          },
          {
            label: "Perubahan Emosional",
            value: [
              {
                type: "image",
                value: image3_4,
              },
              {
                type: "text",
                value:
                  "Dalam masa ini, banyak perubahan yang terjadi dalam diri Kamu. Kamu mulai bisa mengendalikan dorongan emosional yang muncul, merencanakan masa depan, dan memikirkan konsekuensi yang akan Kamu hadapi jika melakukan perbuatan yang tidak baik. Kamu juga mulai memahami apa yang Kamu inginkan dan bisa mengatur diri sendiri, tanpa mengikuti kehendak orang lain.",
              },
              {
                type: "text",
                value:
                  "Jadilah calon manusia dewasa yang bertanggung jawab dan bermanfaat bagi sesama.",
              },
              {
                type: "text",
                value:
                  "Kunjungi fitur edukasi untuk mendapatkan segudang tips dan trik pola hidup sehat remaja agar masa remajamu lebih sehat dan produktif.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: "Laki-laki",
    value: [
      {
        title: "Saat ini kamu berada pada tahap",
        label: "Remaja Awal 10-13 tahun",
        text: "Beberapa hal yang kamu alami di tahap ini adalah sebagai berikut:",
        image: img1_1,
        value: [
          {
            label: "Perubahan Fisik",
            value: [
              {
                type: "image",
                value: img1_2,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, Kamu mulai tumbuh lebih cepat dan mengalami tahap awal pubertas. ",
              },
              {
                type: "text",
                value:
                  "Kamu akan mengalami perubahan tubuh yang seringkali menjadi perhatian Kamu, seperti tumbuh rambut di ketiak dan sekitar alat kelamin, pembesaran pada testis, dan mengalami mimpi basah.",
              },
              {
                type: "text-bold",
                value:
                  "Hadapi perubahan fisik dengan percaya diri dan selalu menjaga kebersihan diri. Tidak perlu takut atau cemas dengan perubahan fisik yang Kamu alami, karena hal tersebut adalah normal.",
              },
            ],
          },
          {
            label: "Perubahan Emosional",
            value: [
              {
                type: "image",
                value: img1_3,
              },
              {
                type: "text",
                value:
                  "Pada tahap ini, Kamu mulai mengalami peningkatan dalam minat seksual, sehingga timbul rasa tertarik dengan lawan jenis.",
              },
              {
                type: "image",
                value: img1_4,
              },
              {
                type: "text",
                value:
                  "Kamu juga mulai memiliki pemikiran yang konkrit. Misalnya, Kamu mulai mencari kebenaran mengenai suatu hal (bisa baik atau buruk) dari berbagai sumber.",
              },
              {
                type: "text",
                value:
                  "Kamu juga mulai lebih memperhatikan penampilan mulai memerlukan privasi, sehingga membuat Kamu lebih senang menyendiri dari keluarga.",
              },
              {
                type: "text-bold",
                value:
                  "Jalani tahap remaja awal Kamu dengan penuh tanggung jawab dan selalu membekali diri dengan ilmu pengetahuan dan ilmu agama agar Kamu tidak terjerumus pada jalan yang salah (kenakalan remaja).",
              },
              {
                type: "text",
                value:
                  "Kunjungi fitur edukasi untuk mendapatkan segudang tips dan trik pola hidup sehat remaja agar masa remajamu lebih sehat dan produktif.",
              },
            ],
          },
        ],
      },
      {
        title: "Saat ini kamu berada pada tahap",
        label: "Remaja Tengah 14-17 tahun",
        text: "Beberapa hal yang kamu alami di tahap ini adalah sebagai berikut:",
        image: img2_1,
        value: [
          {
            label: "Perubahan Fisik",
            value: [
              {
                type: "image",
                value: img2_2,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, pertumbuhan Kamu mulai berjalan cepat. Tubuh Kamu akan semakin tinggi, berat badan bertambah, otot semakin membesar, alat vital semakin besar, suara menjadi pecah, muncul jerawat, kumis, hingga jambang.",
              },
              {
                type: "image",
                value: img2_3,
              },
              {
                type: "text-bold",
                value:
                  "Hadapi perubahan fisik dengan penuh percaya diri dan selalu menjaga kebersihan.",
              },
            ],
          },
          {
            label: "Perubahan Emosional",
            value: [
              {
                type: "image",
                value: img2_4,
              },
              {
                type: "text",
                value:
                  "Pada tahap ini, umumnya Kamu sudah dapat berpikir dengan logika meski masih sering didorong oleh perasaannya. Kamu juga mulai tertarik dengan hubungan romantis (pacaran). Pada masa ini, Kamu juga cukup sensitif, sehingga membuat Kamu lebih sering beradu pendapat dengan orang tua.",
              },
              {
                type: "image",
                value: img2_5,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, Kamu juga lebih senang menghabiskan waktu dengan teman",
              },
              {
                type: "image",
                value: img2_6,
              },
              {
                type: "text",
                value:
                  "Pada masa ini, perkuat ilmu agama dan ilmu pengetahuan, serta pilihlah circle pertemanan yang positif agar Kamu tidak terjerumus pada jalan yang salah (kenakalan remaja). Kamu juga bisa mengisi masa pertengahan remaja ini dengan kegiatan positif, seperti mengikuti perlombaan dan bergabung dalam organisasi agar masa remaja Kamu lebih produktif dan gemilang.",
              },
              {
                type: "text",
                value:
                  "Kunjungi fitur edukasi untuk mendapatkan segudang tips dan trik pola hidup sehat remaja agar masa remajamu lebih sehat dan produktif.",
              },
            ],
          },
        ],
      },
      {
        title: "Saat ini kamu berada pada tahap",
        label: "Remaja Akhir 18-24 tahun",
        text: "Beberapa hal yang kamu alami di tahap ini adalah sebagai berikut:",
        image: img3_1,
        value: [
          {
            label: "Perubahan Fisik",
            value: [
              {
                type: "text",
                value: "Pada masa ini, fisik Kamu telah berkembang sepenuhnya.",
              },
              {
                type: "image",
                value: img3_2,
              },
              {
                type: "text",
                value:
                  "Jaga selalu kesehatan Kamu dengan pola hidup bersih dan sehat agar Kamu bisa memasuki masa dewasa dengan sehat dan produktif.",
              },
            ],
          },
          {
            label: "Perubahan Emosional",
            value: [
              {
                type: "image",
                value: img3_3,
              },
              {
                type: "text",
                value:
                  "Dalam masa ini, banyak perubahan yang terjadi dalam diri Kamu. Kamu mulai bisa mengendalikan dorongan emosional yang muncul, merencanakan masa depan, dan memikirkan konsekuensi yang akan Kamu hadapi jika melakukan perbuatan yang tidak baik. Kamu juga mulai memahami apa yang Kamu inginkan dan bisa mengatur diri sendiri, tanpa mengikuti kehendak orang lain.",
              },
              {
                type: "text",
                value:
                  "Jadilah calon manusia dewasa yang bertanggung jawab dan bermanfaat bagi sesama.",
              },
              {
                type: "text",
                value:
                  "Kunjungi fitur edukasi untuk mendapatkan segudang tips dan trik pola hidup sehat remaja agar masa remajamu lebih sehat dan produktif.",
              },
            ],
          },
        ],
      },
    ],
  },
];
