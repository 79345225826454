import "./App.css";
import app from "./config/Firebase";
import { AppContextProvider } from "./context/AppContextProvider";
import { UserContext } from "./context/UserContext";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { useContext, useEffect, useState } from "react";
import { UserServices } from "./services/UserServices";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { Loader, Loading } from "./components/Loader";
import AuthRouterPage from "./router/AuthRouterPage";
import UserRouterPage from "./router/UserRouterPage";
import AdminRouterPage from "./router/AdminRouterPage";
import { VisitorServices } from "./services/VisitorServices";
const auth = getAuth(app);

const queryClient = new QueryClient();
const userServices = new UserServices();
const visitorServices = new VisitorServices();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <UserManager />
      </AppContextProvider>
    </QueryClientProvider>
  );
}

export default App;

function UserManager() {
  const { user, setUser } = useContext(UserContext);
  const [load, setLoad] = useState(true);

  const { isLoading, error, data } = useQuery(
    "postVisitor",
    async () => await visitorServices.add()
  );

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    onAuthStateChanged(auth, async function (users) {
      if (users) {
        const resUser = await userServices.getUser(users.email);
        setUser(resUser);
        setLoad(false);
      } else {
        setUser(null);
        setLoad(false);
      }
    });
  }

  if (load) {
    return (
      <div className="w-full h-screen flex justify-center items-center bg-white">
        <Loader />
      </div>
    );
  } else if (user == null && load == false) {
    return <AuthRouterPage />;
  } else if (user.role === "user") {
    return <UserRouterPage />;
  } else if (user.role === "admin") {
    return <AdminRouterPage />;
  } else {
    return <h1>404 Page</h1>;
  }
}
