import { FiX } from "react-icons/fi";

export function Visitor({ total, daily, monthly, callback }) {
  return (
    <div className="px-7 py-2 bg-white rounded-full shadow-s3 grid grid-cols-12 gap-3 relative">
      <button
        onClick={callback}
        className="absolute -right-2 -top-2 text-primary-main"
      >
        <FiX size={20} />
      </button>
      <div className="col-span-4">
        <p className="text-xs text-neutral-600 text-center">Total</p>
        <h5 className="f-h5 text-neutral-900 text-center">{total ?? "-"}</h5>
      </div>
      <div className="col-span-4">
        <p className="text-xs text-neutral-600 text-center">Harian</p>
        <h5 className="f-h5 text-primary-main text-center">{daily ?? "-"}</h5>
      </div>
      <div className="col-span-4">
        <p className="text-xs text-neutral-600 text-center">Bulanan</p>
        <h5 className="f-h5 text-neutral-900 text-center">{monthly ?? "-"}</h5>
      </div>
    </div>
  );
}
