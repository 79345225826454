import React, { useContext } from "react";

import { Router, BrowserRouter, Switch, Route, Routes } from "react-router-dom";
import { Loader } from "src/components/Loader";
import { LoadingContext } from "src/context/LoadingContext";
import { SidebarContext } from "src/context/SidebarContext";
import AdminLayout from "src/layouts/AdminLayout";
import { AdminArtikelPage } from "src/pages/admin-artikel";
import { AdminCreateArtikelPage } from "src/pages/admin-artikel/tambah";
import { AdminEditArtikelPage } from "src/pages/admin-artikel/ubah";
import { AdminDashboardPage } from "src/pages/admin-dashboard";
import { AdminProfilePage } from "src/pages/admin-profile";
import { AdminChangePasswordPage } from "src/pages/admin-profile/change-password";
import { AdminEditProfilePage } from "src/pages/admin-profile/edit-profile";
import { AdminScreeningPage } from "src/pages/admin-screening";
import { AdminVideoPage } from "src/pages/admin-video";
import { AdminCreateVideoPage } from "src/pages/admin-video/tambah";
import { AdminEditVideoPage } from "src/pages/admin-video/ubah";

export default function AdminRouterPage() {
  const { sidebar, setSidebar } = useContext(SidebarContext);
  const { loading, setLoading } = useContext(LoadingContext);

  return (
    <BrowserRouter>
      <AdminLayout>
        <div className="bg-gray-100 p-6 mt-16 lg:w-10/12 w-full text-dark min-h-screen">
          <Routes>
            <Route path="/" element={<AdminDashboardPage />} />
          </Routes>
          <Routes>
            <Route path="/skrining" element={<AdminScreeningPage />} />
          </Routes>
          <Routes>
            <Route path="/video" element={<AdminVideoPage />} />
          </Routes>
          <Routes>
            <Route path="/video/tambah" element={<AdminCreateVideoPage />} />
          </Routes>
          <Routes>
            <Route path="/video/:id/ubah" element={<AdminEditVideoPage />} />
          </Routes>
          <Routes>
            <Route path="/artikel" element={<AdminArtikelPage />} />
          </Routes>
          <Routes>
            <Route
              path="/artikel/tambah"
              element={<AdminCreateArtikelPage />}
            />
          </Routes>
          <Routes>
            <Route
              path="/artikel/:id/ubah"
              element={<AdminEditArtikelPage />}
            />
          </Routes>
          <Routes>
            <Route path="/profile" element={<AdminProfilePage />} />
          </Routes>

          <Routes>
            <Route
              path="/ganti-password"
              element={<AdminChangePasswordPage />}
            />
          </Routes>
          <Routes>
            <Route path="/edit-profile" element={<AdminEditProfilePage />} />
          </Routes>
        </div>
      </AdminLayout>
    </BrowserRouter>
  );
}
