import imageLogo2 from "src/assets/Illustration/Logo.svg";
import imageMail from "src/assets/Iconography/Solid/mail.svg";
import imageEllipse from "src/assets/Illustration/Ellipse 4.png";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { AuthServices } from "src/services/AuthServices";
import { LoadingContext } from "src/context/LoadingContext";

export function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(LoadingContext);
  const [userEmail, setUserEmail] = useState("");
  const authServices = new AuthServices();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const res = await authServices.forgotPassword({ email: userEmail });
    setLoading(false);
  };

  return (
    <div className="w-full lg:min-h-screen bg-primary-surface flex justify-center items-center relative overflow-hidden">
      <img src={imageEllipse} className="absolute w-full top-72" />
      <div className="container lg:w-[480px] w-full bg-white shadow-s3 lg:rounded-2xl p-6 relative">
        <div className="lg:mt-0 mt-16">
          <img src={imageLogo2} className="mx-auto" />
        </div>
        <h1 className="f-h3 text-neutral-1000 text-center mt-5">
          Lupa Password?
        </h1>

        <p className="f-p1-r mt-2 text-center">
          Jangan khawatir, masukkan email yang terdaftar untuk melakukan reset
          password
        </p>

        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="mt-4 relative">
            <label className="f-p1-m text-neutral-1000">Email</label>
            <img
              src={imageMail}
              className="absolute w-6 bottom-[11px] left-3"
            />
            <input
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
              name="email"
              value={userEmail}
              placeholder="Email address"
              className="w-full border-2 bg-neutral-200 border-neutral-200 rounded-lg pl-11 px-4 py-2 mt-2 focus:outline-none focus:border-primary-main"
            />
          </div>

          <div className="mt-6">
            <ButtonPrimaryExpand
              text="Kirim Petunjuk Reset"
              callback={() => {}}
              type="submit"
            />
          </div>
          <div className="mt-2">
            <ButtonSecondaryExpand
              text="Batal"
              callback={() => {
                navigate("/");
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
