import { useContext, useEffect, useState } from "react";
import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { useNavigate } from "react-router-dom";
import { UserContext } from "src/context/UserContext";

import imageLoading from "src/assets/Illustration/loading.svg";
import { QuestionPuzzle } from "./section/question";

export function GamePuzzlePage() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [answers, setAnswers] = useState([]);

  const [randomQuestions, setRandomQuestions] = useState([]);

  useEffect(() => {}, []);

  return (
    <div className="w-full bg-white">
      <div className="container lg:pt-24 pt-8 mx-auto mb-8 min-h-screen lg:w-[480px] w-11/12 flex flex-col items-center">
        {currentQuestion === 0 ? (
          <div className="flex flex-col w-11/12 lg:w-full pt-20">
            <h1 className="f-h1 mb-8">Petunjuk Permainan: Poster Puzzle</h1>
            <ol className="text-left list-decimal mb-4">
              <li className="f-p1-r mb-2">
                Kamu disediakan puzzle berisi poster yang susunan gambarnya
                telah diacak
              </li>
              <li className="f-p1-r mb-2">
                Tugasmu adalah menyusun agar potongan-potongan acak tersebut
                membentuk gambar yang diinginkan dengan cara menukar posisinya
              </li>
            </ol>

            <div className="mb-2">
              <h3 className="f-p1-r text-center">Siap bermain?</h3>
            </div>

            <div className="mb-2 w-full">
              <ButtonPrimaryExpand
                text="Siap Mulai"
                callback={() => {
                  setCurrentQuestion(1);
                }}
              />
            </div>
          </div>
        ) : currentQuestion == 1 ? (
          <QuestionPuzzle
            handlePrev={() => {
              setCurrentQuestion(0);
            }}
          />
        ) : null}

        <div className="w-11/12 lg:w-full"></div>
      </div>
    </div>
  );
}
