import { Footer } from "src/components/footer";
import { NavbarDashboard } from "src/components/navbar";
import React, { useContext } from "react";
import { CardRecommendation } from "./component/card-recommendation";
import YouTube, { YouTubeProps } from "react-youtube";
import { useParams } from "react-router-dom";
import { EducationServices } from "src/services/EducationServices";
import { useQuery } from "react-query";
import { Loader } from "src/components/Loader";
import urlParser from "js-video-url-parser";
import { UserContext } from "src/context/UserContext";

export function VideoDetailPage() {
  const { id } = useParams();
  const educationServices = new EducationServices();

  const { user, setUser } = useContext(UserContext);

  const educationForYou = useQuery(
    "educationsForYou",
    async () =>
      await educationServices.getForMe({
        code: `${user?.screening.age}-${user?.screening.imt}-${user?.screening.blood_pressure}`,
      })
  );

  const video = useQuery(
    "video",
    async () => await educationServices.getById(id)
  );

  const opts = {
    height: window.screen.width > 768 ? "500" : "300",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="bg-neutral-100">
      <NavbarDashboard />
      <div className="container w-11/12 lg:pt-28 pt-24 mx-auto mb-8">
        <div className="grid grid-cols-12 gap-5 w-full">
          {video.isLoading ? (
            <Loader />
          ) : video.isError ? (
            <p>Something went wrong</p>
          ) : (
            <div className="lg:col-span-8 col-span-12 bg-neutral-100">
              <div className="">
                <YouTube
                  videoId={urlParser.parse(video.data.link).id}
                  opts={opts}
                />
              </div>
              <h2 className="f-h2 mt-4">{video.data.title}</h2>
              <p className="f-p1-r text-justify">{video.data.description} </p>
            </div>
          )}

          <div className="lg:col-span-4 col-span-12 bg-neutral-100">
            <div>
              <h3 className="f-h4">
                Konten Yang Masih Menjadi Rekomendasi Untukmu
              </h3>
            </div>
            {educationForYou.isLoading ? (
              <Loader />
            ) : educationForYou.isError ? (
              <p>Something went wrong</p>
            ) : (
              <div>
                {educationForYou.data.map((item, index) => {
                  return <CardRecommendation key={index} item={item} />;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
