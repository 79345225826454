import {
  ButtonPrimaryExpand,
  ButtonSecondaryExpand,
} from "src/components/button";
import { NavQuestion } from "../component/nav";
import { useEffect, useRef, useState } from "react";

import imagePuzzle1 from "src/assets/Game/puzzle/1.jpg";
import imagePuzzle2 from "src/assets/Game/puzzle/2.jpg";
import imagePuzzle3 from "src/assets/Game/puzzle/3.jpg";
import imagePuzzle4 from "src/assets/Game/puzzle/4.jpg";
import imagePuzzle5 from "src/assets/Game/puzzle/5.jpg";
import imagePuzzle6 from "src/assets/Game/puzzle/6.jpg";
import imagePuzzle7 from "src/assets/Game/puzzle/7.jpg";
import imagePuzzle8 from "src/assets/Game/puzzle/8.jpg";
import imagePuzzle9 from "src/assets/Game/puzzle/9.jpg";
import imagePuzzle10 from "src/assets/Game/puzzle/10.jpg";
import imagePuzzle11 from "src/assets/Game/puzzle/11.jpg";
import imagePuzzle12 from "src/assets/Game/puzzle/12.jpg";
import { useNavigate } from "react-router-dom";
import { PopUpResult } from "../component/pop-up-result";

const images = [
  imagePuzzle1,
  imagePuzzle2,
  imagePuzzle3,
  imagePuzzle4,
  imagePuzzle5,
  imagePuzzle6,
  imagePuzzle7,
  imagePuzzle8,
  imagePuzzle9,
  imagePuzzle10,
  imagePuzzle11,
  imagePuzzle12,
];

const rightAnswer = [
  { col: 0, row: 0 },
  { col: 1, row: 0 },
  { col: 2, row: 0 },
  { col: 0, row: 1 },
  { col: 1, row: 1 },
  { col: 2, row: 1 },
  { col: 0, row: 2 },
  { col: 1, row: 2 },
  { col: 2, row: 2 },
];

export function QuestionPuzzle({ handlePrev }) {
  const navigate = useNavigate();
  const cols = 3;
  const rows = 3;

  const [answer, setAnswer] = useState([
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
    { col: null, row: null },
  ]);

  const [image, setImage] = useState(null);
  const [indexImage, setIndexImage] = useState(null);
  const [randomPosition, setRandomPosition] = useState([
    { col: 0, row: 0 },
    { col: 1, row: 0 },
    { col: 2, row: 0 },
    { col: 0, row: 1 },
    { col: 1, row: 1 },
    { col: 2, row: 1 },
    { col: 0, row: 2 },
    { col: 1, row: 2 },
    { col: 2, row: 2 },
  ]);

  const [randomPositionSelected, setRandomPositionSelected] = useState(null);

  const [validationAnswer, setValidationAnswer] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  function fetch() {
    const idxRandom = Math.floor(Math.random() * 12);
    setIndexImage(idxRandom);
    const img = new Image();
    img.src = images[idxRandom];

    const rPosition = shuffleArray(randomPosition);
    setRandomPosition(rPosition);

    img.addEventListener("load", () => {
      const width = 480;
      const height = (480 / img.width) * img.height;
      setImage({ ...img, width: width, height: height });
    });
  }

  const shuffleArray = (dataArray) => {
    const shuffledIndices = [];
    const copyArray = [...dataArray];

    while (copyArray.length > 0) {
      const randomIndex = Math.floor(Math.random() * copyArray.length);
      const removedItem = copyArray.splice(randomIndex, 1)[0];
      shuffledIndices.push(removedItem);
    }

    return shuffledIndices;
  };

  return (
    <div className={`flex flex-col w-full`}>
      {validationAnswer == true ? (
        <div className="flex justify-center items-center fixed left-0 top-0 z-10 w-full h-full bg-black bg-opacity-20">
          <PopUpResult
            image={images[indexImage]}
            handleTryAgin={handlePrev}
            handleHome={() => {
              navigate("/");
            }}
          />
        </div>
      ) : null}
      <div className="mb-10">
        <NavQuestion handlePrev={handlePrev} value={1 * 100 + "%"} />
      </div>

      <div className="mb-8">
        <h2 className="f-h2 mb-1">Poster Puzzle</h2>
        <p className="f-p1-r">
          Susunlah tiap potongan sehingga membentuk sebuah poster dengan cara
          menukar posisinya.
        </p>
      </div>

      <div className="mb-5 justify-center">
        <div className="bg-white rounded-[8px] overflow-hidden">
          {image == null ? null : (
            <div className="flex flex-col gap-1">
              {Array(cols)
                .fill(0)
                .map((col, index) => {
                  return (
                    <div key={index} className="flex flex-row gap-1">
                      {Array(rows)
                        .fill(0)
                        .map((row, i) => {
                          return (
                            <button
                              key={`${index}_${i}`}
                              onClick={() => {
                                if (
                                  answer[index * cols + i].col == null &&
                                  randomPositionSelected != null
                                ) {
                                  var tempAnswer = [...answer];
                                  tempAnswer[index * cols + i] =
                                    randomPositionSelected;
                                  setAnswer(tempAnswer);

                                  var tempRandomPosition = [...randomPosition];
                                  const indexRandomPositionSelected =
                                    tempRandomPosition.indexOf(
                                      randomPositionSelected
                                    );
                                  tempRandomPosition.splice(
                                    indexRandomPositionSelected,
                                    1
                                  );
                                  setRandomPosition(tempRandomPosition);

                                  setRandomPositionSelected(null);
                                } else if (
                                  answer[index * cols + i].col != null
                                ) {
                                  var tempRandom = [...randomPosition];
                                  tempRandom.push(answer[index * cols + i]);
                                  setRandomPosition(tempRandom);

                                  var tempAnswer = [...answer];
                                  tempAnswer[index * cols + i] = {
                                    col: null,
                                    row: null,
                                  };
                                  setAnswer(tempAnswer);
                                }
                              }}
                              className="bg-white overflow-hidden relative"
                              style={{
                                width: image.width / cols,
                                height: image.height / rows,
                              }}
                            >
                              {" "}
                              {answer[index * rows + i].col == null ? (
                                <div
                                  className="bg-primary-border"
                                  style={{
                                    width: image.width,
                                    height: image.height,
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className="absolute"
                                  style={{
                                    width: image.width,
                                    height: image.height,
                                    left:
                                      -answer[index * rows + i].col *
                                      (image.width / cols),
                                    top:
                                      -answer[index * cols + i].row *
                                      (image.height / rows),
                                  }}
                                >
                                  <img
                                    src={images[indexImage]}
                                    alt=""
                                    className="absolute"
                                  />
                                </div>
                              )}
                            </button>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>

      <div className="mb-8 lg:w-[480px] w-full overflow-auto">
        {image == null ? null : (
          <div className="bg-white w-fit flex gap-0 rounded-[8px] relative">
            {randomPosition.map((el, idx) => {
              return (
                <button
                  onClick={() => {
                    setRandomPositionSelected(el);
                  }}
                  key={idx}
                  className={`overflow-hidden relative border-4 ${
                    randomPositionSelected == el
                      ? "border-primary-main"
                      : "border-transparent"
                  }`}
                  style={{
                    width: image.width / cols,
                    height: image.height / rows,
                  }}
                >
                  <div
                    className="absolute"
                    style={{
                      width: image.width,
                      height: image.height,
                      left: -el.col * (image.width / cols),
                      top: -el.row * (image.height / rows),
                    }}
                  >
                    <img
                      src={images[indexImage]}
                      alt=""
                      className="absolute w-full h-full"
                    />
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </div>

      <div className="mb-2">
        <ButtonPrimaryExpand
          text="Cek Hasil"
          callback={() => {
            var validate = false;
            for (let i = 0; i < rightAnswer.length; i++) {
              if (
                rightAnswer[i].col == answer[i].col &&
                rightAnswer[i].row == answer[i].row
              ) {
                validate = true;
              } else {
                validate = false;
              }
            }

            setValidationAnswer(validate);
          }}
        />
      </div>

      <div className="mb-4">
        <ButtonSecondaryExpand
          text="Ganti Gambar"
          callback={() => {
            setAnswer([
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
              { col: null, row: null },
            ]);
            fetch();
          }}
        />
      </div>
    </div>
  );
}
