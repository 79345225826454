export function CardLarge(props) {
  return (
    <div className="bg-white rounded-xl lg:py-8 py-4 px-5 lg:shadow-s3 flex lg:flex-col lg:text-center text-left gap-5">
      <div className="flex justify-center bg-white lg:bg-transparent shadow-s3 lg:shadow-none p-3 my-auto w-[90px] h-[90px] lg:w-[120px] lg:h-[120px] rounded-md m-auto">
        <img src={props.image} className="" />
      </div>
      <div>
        <h3 className="lg:f-h3 f-h4 text-neutral-1000 ">{props.title}</h3>
        <p className="f-p1-r text-neutral-900 lg:mt-3 mt-2">
          {props.description}
        </p>
      </div>
    </div>
  );
}

export function CardSmall(props) {
  return (
    <div className="bg-white rounded-xl py-8 px-5 flex flex-col lg:flex-row items-center shadow-s3">
      <div className="flex justify-center">
        <img src={props.image} />
      </div>
      <h3 className="lg:f-h3 f-h5 text-neutral-1000 mt-5">{props.title}</h3>
    </div>
  );
}

export function CardCreator(props) {
  return (
    <div className="py-8 px-5">
      <div className="flex justify-center">
        <img src={props.image} />
      </div>
      <p className="f-p1-r text-neutral-900 mt-3">{props.name}</p>
    </div>
  );
}
